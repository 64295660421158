// add useContext
import React, { useContext, useState, useEffect } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import firebase from "firebase";
import { Signup } from "./SignUp";
import {
  useHistory
} from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SimpleAccordion from "./SimpleAccordian";


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/logo_ifm.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Signin = () => {
  const classes = useStyles();
  const history = useHistory()
  const {
    handleSignin,
    handleSigninEmailLink,
    inputs,
    setInputs,
    errors,
    token,
    handlesigninGoogle,
    handleSigninGoogleAfterRedirect
  } = useContext(firebaseAuth);




  useEffect(() => {
    return firebase.auth().onAuthStateChanged((im) => {
      if (im) {
        handleSigninGoogleAfterRedirect(im)
        history.push("/")
        // setIsInitialized(true);
        // setUser({
        //   displayName: im.displayName,
        //   email: im.email,
        //   photo: im.photoURL,
        //   uid: im.uid,
        // });
      } else {
        // setIsInitialized(true);
        // setUser(null);
        // alert("false")
        history.push("/signinemail")
      }
    });
  }, []);


  const delay = ms => new Promise(res => setTimeout(res, ms));
  
  const handleSubmit =async (e) => {
    e.preventDefault();
    await handleSignin();
    await delay(2000)

    if(window.localStorage.getItem('token') !== null)
     history.push("/")
     else
     history.push("/forgotPassword")

    //  errors.length > 0
    //          ? errors.map((error) => (

    //         ))
    //       :null

  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(inputs);
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitEmailLink = (e) => {
    e.preventDefault();
    console.log("handleSubmitEmailLink");
    handleSigninEmailLink();
  };

  const Toggle = (props) => {
    const [show, toggleShow] = React.useState(false);
  
    return (
      <div>  
        <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={() => toggleShow(!show)}
            >
              Login with your own email address
            </Button>
            <br/>
        {show && props.children}
      </div>
    )
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} > 
      <div style={{display: "flex", textAlign: "center", justifyContent: "center"}}>
        <img style={{margin: "0 auto;"}} src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/logo_ifm.png"/>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      {/* <div className={classes.paper}>
      <Typography component="h1" variant="h5">
            Not a subscriber?
          </Typography>

          <Typography  variant="subtitle">
            After payment is succesful, your license will be emailed
          </Typography>

          <Link href="https://21ifm.gumroad.com/l/options" variant="body2">
                  {"Link to Payment to get single user License "}
          </Link>
      </div> */}
      <div className={classes.paper}>
      {/* <Typography component="h1" variant="h5">
            Register here after payment
          </Typography>
         <Link href="/register" variant="body2">
                  {"Link to Register License"}
        </Link> */}

      </div>
        <div className={classes.paper}>
        <Typography component="h1" variant="h5">
               New user
          </Typography>
      
                    <Link href="/signup" variant="body2" style={{color: "yellow"}}>
                     signup link
                    </Link>

          <br/>
          <br/>
          <br/>

          {/* <Typography component="h1" variant="h5">
              or Signed up already
          </Typography> */}
      
          </div>
          <div className={classes.paper}>
          Login with your email if already signed up
            <div> 
            <form className={classes.form} noValidate >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
     
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgotPassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
 
            </Grid>
            <br/>


            <Box mt={5}>

            <Link href="mailto:office@21ifm.com?subject=I have a question&body=Thanks!" variant="body2">
                  {"Question?"}
          </Link>
            
            {errors.length > 0
             ? errors.map((error) => (
              <>
                <p style={{ color: "red" }}>{error}</p>
              
              </>
            ))
          :null}
            </Box>
          </form>
          </div>
     
     
        </div>
      </Grid>
    </Grid>
  );
};

export default Signin;