import React, { useState } from 'react';
import './style.css'

import { Portal } from "react-portal";
import Draggable from 'react-draggable';


const handleDragStop = (e) => {
    e.stopPropagation();
    // drag logic
  };


const MyDraggableComponent = (props) => {
  const { setIsModalOpen } = props;
  return (
    <>
        <div onClick={handleDragStop} style={{overflowY: 'auto', maxHeight: '55v%'}}>

          <Portal onClick={handleDragStop}>
            <Draggable onStop={handleDragStop} >
              <div
                className="modal"
                style={{
                  position: 'fixed',
                  top: 25,
                  float: 'right',
                  left: 10,
                  zIndex: 100000000000,
                  display: 'flex'
                }}
              >
                <span style={{ position: 'absolute', color: 'white', zIndex: 100000 }}>
                  This window can be dragged
                </span>
                {props.children}
                <button
                  style={{
                    // backgroundColor: '#F9A51A',
                    // color: 'white',
                    padding: '5px 10px',
                    border: 'none',
                    borderRadius: '5px',
                    position: 'fixed',
                    top: 5,
                    float :' right',
                    right: 5
                }}
                  onClick={() => setIsModalOpen(false)}
                >
                   Close
                </button>
              </div>
            </Draggable>
          </Portal>
        </div>
    </>
  );
};

export default MyDraggableComponent;
