import React, { useState, useContext, useEffect } from "react";
import firebase from 'firebase';
import './styles.css'
import { firebaseAuth } from "../../provider/AuthProvider";
///Users/pavanmirla/Projects/21ifm_firebase/JUL14_IFM_OPTIONS/src/components/Layouts/ConceptBadges.js
///Users/pavanmirla/Projects/21ifm_firebase/JUL14_IFM_OPTIONS/src/provider/AuthProvider.js

// const AlphabetBlocks = ({ concepts }) => {
//     let groupedConcepts = {};
//     if (concepts) {
//       // Group concepts by alphabet
//       groupedConcepts = concepts.reduce((groups, concept) => {
//         const alphabet = concept.charAt(0).toUpperCase();
//         if (!groups[alphabet]) {
//           groups[alphabet] = [];
//         }
//         groups[alphabet].push(concept);
//         return groups;
//       }, {});
//     }
  
//     // Render alphabet blocks
//     return (
//       <div className="alphabet-blocks">
//         {Object.keys(groupedConcepts).map((alphabet) => (
//           <div className="alphabet-block" key={alphabet}>
//             <div className="alphabet-heading">
//               <h3>{alphabet}</h3>
//             </div>
//             <div className="concept-badges">
//               {groupedConcepts[alphabet].map((concept) => (
//                 <div className="concept-badge" key={concept}>
//                   {concept}
//                 </div>
//               ))}
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   };
  
//   export default AlphabetBlocks;


// const AlphabetBlocks = ({ concepts }) => {
//   const [selectedBadges, setSelectedBadges] = useState([]);

//   let groupedConcepts = {};
//   if (concepts) {
//     // Group concepts by alphabet
//     groupedConcepts = concepts.reduce((groups, concept) => {
//       const alphabet = concept.charAt(0).toUpperCase();
//       if (!groups[alphabet]) {
//         groups[alphabet] = [];
//       }
//       groups[alphabet].push(concept);
//       return groups;
//     }, {});
//   }

//   // Toggle the selected state of a badge
//   const toggleBadgeSelected = (badge) => {
//     if (selectedBadges.includes(badge)) {
//       setSelectedBadges(selectedBadges.filter((b) => b !== badge));
//     } else {
//       setSelectedBadges([...selectedBadges, badge]);
//     }
//   };

//   // Render alphabet blocks
//   return (
//     <div className="alphabet-blocks">
//       {Object.keys(groupedConcepts).map((alphabet) => (
//         <div className="alphabet-block" key={alphabet}>
//           <div className="alphabet-heading">
//             <h3>{alphabet}</h3>
//           </div>
//           <div className="concept-badges">
//             {groupedConcepts[alphabet].map((concept) => (
//               <div className="concept-badge" key={concept}>
//                 <input
//                   type="checkbox"
//                   checked={selectedBadges.includes(concept)}
//                   onChange={() => toggleBadgeSelected(concept)}
//                 />
//                 {concept}
//               </div>
//             ))}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default AlphabetBlocks;





const AlphabetBlocks = ({ concepts, userId }) => {
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [uid, setUid] = useState(null);


  const [isSaving, setIsSaving] = useState(false);
  const { token, handleSignout, inputs, currentUser , setCurrentUser, setPaymentFlag,paymentFlag , currentUserId} = useContext(firebaseAuth);


  let groupedConcepts = {};
  if (concepts) {
    // Group concepts by alphabet
    groupedConcepts = concepts.reduce((groups, concept) => {
      const alphabet = concept.charAt(0).toUpperCase();
      if (!groups[alphabet]) {
        groups[alphabet] = [];
      }
      groups[alphabet].push(concept);
      return groups;
    }, {});
  }

  // Toggle the selected state of a badge
  const toggleBadgeSelected = (badge) => {
    if (selectedBadges.includes(badge)) {
      setSelectedBadges(selectedBadges.filter((b) => b !== badge));
    } else {
      setSelectedBadges([...selectedBadges, badge]);
    }
  };

  // Save the selected badges to Firestore
  const saveBadges = () => {
    setIsSaving(true);
    if (uid) {
      const db = firebase.firestore();
      const badgesRef = db.collection('badges').doc(uid);
      badgesRef.set({ badges: selectedBadges })
        .then(() => {
          setIsSaving(false);
          console.log('Badges saved to Firestore');
        })
        .catch((error) => {
          setIsSaving(false);
          console.error('Error saving badges:', error);
        });
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in. Get the user's UID.
        setUid(user.uid);
      } else {
        // User is signed out.
      }
    });

    return () => unsubscribe();
  }, []);


  // Render alphabet blocks
  return (
    <>
    <h3>User: {uid}</h3>
    <div className="alphabet-blocks">
      {Object.keys(groupedConcepts).map((alphabet) => (
        <div className="alphabet-block" key={alphabet}>
          <div className="alphabet-heading">
            <h3>{alphabet}</h3>
          </div>
          <div className="concept-badges">
            {groupedConcepts[alphabet].map((concept) => (
              <div className="concept-badge" key={concept}>
              <input
                type="checkbox"
                checked={selectedBadges.includes(concept)}
                onChange={() => toggleBadgeSelected(concept)}
              />
              {concept}
            </div>
          ))}
        </div>
      </div>
    ))}
    <button onClick={saveBadges} disabled={isSaving}>
      {isSaving ? 'Saving...' : 'Save'} {uid}
    </button>
  </div>
  </>
);
};

export default AlphabetBlocks;

