
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import SimpleAccordion from "../../components/SimpleAccordian";
import IfPaidUser from "../../components/IfPaidUser";
import useFetchData from "../../components/UseFetchData";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ReactPlayerComp from "../../components/ReactPlayerComp";
import SimpleDialog from "../../components/SimpleDialog";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import BlueLink from "../../components/Layouts/BlueLink";
import "../../components/style.css";
import LandmarkButton from '../../components/LandmarkButton';
import MyAudioPlayer from '../../components/Layouts/AudioPlayer';
import MyDraggableComponent from "../../components/DraggableComponent";
import GRCard from "../../components/GRCard";
import { Carousel } from 'react-responsive-carousel';
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "Which of these options is correct?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/sp50_q2.png",
  // video: "https://example.com/france.mp4",
  answers: ["1.A", "2. B"],
  correctAnswer: "2. B"
};
export const question2 = {
  text: "What is the Delta of a deep in-the-money call option?",
  //   image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/delta_soft_quiz.mp4",
  answers: ["1. Close to 0", "2. Close to 0.5", "3. Close to 1"],
  correctAnswer: "3. Close to 1"
};
export const question3 = {
  text: "How does the Delta of an at-the-money call option change from the first few days to near its expiry date?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/delta_over_time.mp4",
  answers: ["1. The Delta increases", "2. The Delta decreases", "3. The Delta remains constant"],
  correctAnswer: "1. The Delta increases"
};
export const question4 = {
  text: "How can theta decay impact a short iron condor strategy?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/iron_condor_delta.mp4",
  answers: ["1.  It can have a negative impact on the strategy by increasing the value of both the long and short positions.", "2. It can have a positive impact on the strategy by reducing the value of both the long and short positions", "3. It has no impact on the strategy as it only measures the rate of change in the value of an option over time."],
  correctAnswer: "2. It can have a positive impact on the strategy by reducing the value of both the long and short positions"
};
export const question5 = {
  text: "What is a collar trade??",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/cuban_collar.png",
  //   video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/iron_condor_delta.mp4",
  answers: ["1.  A strategy that involves the purchase of a protective put option and a call option.", "2. A strategy that involves the sale of a put option and the purchase of a call option", "3. A strategy that involves the purchase of a protective put option and the sale of a covered call option."],
  correctAnswer: "3. A strategy that involves the purchase of a protective put option and the sale of a covered call option."
};
export const topics = [{
  title: 'Delta of ITM Call',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
          </Container>
  </div>
}, {
  title: 'Delta over time',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question3} mdxType="Quiz" />
          </Container>
  </div>
}, {
  title: "Mark Cuban's Collar trade",
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question5} mdxType="Quiz" />
          </Container>
  </div>
}, {
  title: 'SPY',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'Short Iron Condor and Theta',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question4} mdxType="Quiz" />
          </Container>
  </div>
} //   {
//     title: 'Topic India. what is this is a long button. how does it look',
//     component: () => (
//    <div style={{
//   backgroundImage: "url('https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/stock_marketpng.png')",
//   backgroundSize: 'cover',
//   backgroundRepeat: 'no-repeat',
//   backgroundPosition: 'center',
//   overflowY: 'scroll',
//   height: '600px',
//   maxHeight: '75vh'
// }}>
//       <Marker left="25%" top="55%"  mleft="50%" mtop="25%"   text="1" >
//                   <Box1/> 
//       </Marker>
//       <BlinkingButton buttonPosition={{left: "76%", top: "64%"}}  text={"3"} url={"https://options.21ifm.com"}/>
//       <BlueLink url="https://options.21ifm.com/sofr" text="sofr" />
//       <div style={{left: "50%", top: "60%" , position: 'absolute', display: 'flex'}}>
//       <SimpleDialog buttonPosition={{left: "0%", top: "0%"}} title={'Options Greeks in detail in 2D and 3D. Options Greeks in detail in 2D and 3D'} text={"1.1"}>
//             <Container>
//               <GeogebraDraw 
//                   jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/landscape/strategy_diag_8.json"
//                   ggbParameters={ggbParameters}
//                   appId="appId1volquiz"
//                   email="pavanmirla@gmail.com"
//                   data={[
//                   {component: <Box2/>, button:  "longcall"}
//                   ]}
//                 /> 
//             </Container>
//       </SimpleDialog>
//       <ButtonMailto label="Email this" mailto="mailto:office@21ifm.com?subject=Note on SOFR chapter" top= {"91%"} left={"88%"}/>
//       </div>
//       <div style={{left: "79%", top: "40%" , position: 'absolute', display: 'flex'}}>
//       <SimpleDialog buttonPosition={{left: "0%", top: "0%"}} title={'Options Greeks in detail in 2D and 3D. Options Greeks in detail in 2D and 3D'} text={"2"}>
//             <Container>
//               <GeogebraDraw 
//                   jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/landscape/strategy_diag_8.json"
//                   ggbParameters={ggbParameters}
//                   appId="appId1volquiz"
//                   email="pavanmirla@gmail.com"
//                   data={[
//                   {component: <Box2/>, button:  "longcall"}
//                   ]}
//                 /> 
//             </Container>
//       </SimpleDialog>
//       </div>
//   </div>
//     )
//   },
//     {
//     title: 'Topic India. what is this is a long button. how does it look',
//     component: () => (
//    <div style={{overflowY: 'scroll', height: '600px', maxHeight: '75vh'}}>
//         <Box1/> 
//   </div>
//     )
//   },
//      {
//     title: 'Text',
//     component: () => (
//       <div style={{
//   backgroundImage: "url('https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/stock_market_2.png')",
//   backgroundSize: 'cover',
//   backgroundRepeat: 'no-repeat',
//   backgroundPosition: 'center',
//   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   overflowY: 'scroll',
//   height: '600px',
//   maxHeight: '75vh'
// }}>
//           <Container>
//             <Quiz question={question1}/>
//              {/* <Quiz question={question2}/> */}
//           </Container>
//   </div>
//     )
//   }
//   ,
//    {
//     title: 'Image',
//     component: () => (
//    <div style={{overflowY: 'scroll', height: '600px', maxHeight: '75vh'}}>
//           <Container>
//             <Quiz question={question2}/>
//              {/* <Quiz question={question2}/> */}
//           </Container>
//   </div>
//     )
//   }
// Add more topics here
];
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MyCarousel = makeShortcode("MyCarousel");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
question4,
question5,
topics,
ggbParameters,
ggbParameters2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">











    <br />
    <br />
    <br />
    <br />
    <h1>{`SAMPLE QUIZ`}</h1>






    <Container mdxType="Container">
  <div>
   <MyCarousel topics={topics} mdxType="MyCarousel" />
  </div>
    </Container>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;