
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import OpenVideo from "../../components/OpenVideo";
import DrawingCanvas from "../../components/DrawingCanvas";
import SaveGeogebra from "../../components/SaveGeogebra";
import MaskedWord from "../../components/MaskedWords";
import ImageAnnotation from "../../components/ImageAnnotation";
import "../../components/style.css";
import Marker from '../../components/ImageMarkers';
import LandmarkButton from '../../components/LandmarkButton';
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import BingoBoard from "../../components/Layouts/BingoBoard";
import BlueLink from "../../components/Layouts/BlueLink";
import ButtonMailto from "../../components/ButtonMailto.js";
import Link from '@mui/material/Link';
import { Portal } from "react-portal";
import Draggable from 'react-draggable';
import  {useState,useEffect,useReducer,useRef} from "react";
import Bullish_Call_Spread from './Bullish_Call_Spread.mdx'
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const MaskedWordsComp1 = () => {
  const sentences = ['Implied volatility is a measure of the expected volatility of the underlying stock or index over the life of the option. It is important in options because it helps to determine the value of the option.', 'The value of an option is influenced by several factors, including the underlying stock price, the strike price of the option, the time until expiration, the risk-free interest rate, and the expected volatility of the underlying stock'];
  const words = ['value', 'expected'];
  return <MaskedWord sentences={sentences} words={words} mdxType="MaskedWord" />;
};
export const DrawCanvas = () => {
  const [trainingData, setTrainingData] = useState([]);

  const handleSave = data => {
    // Save the training data to your database or file system here...
    console.log(trainingData);
    setTrainingData([...trainingData, data]);
  };

  return <DrawingCanvas onSave={handleSave} mdxType="DrawingCanvas" />;
};
export const MaskedWordsComp2 = () => {
  const sentences = ['Of these factors, implied volatility is the only one that is not directly observable . Instead, it must be implied from the current market price of the option.', "Implied volatility is important for options traders because it can be used to gauge market expectations for the stock's future price movement.", "If the implied volatility of an option is high, it means that the market expects the stock to be more volatile over the life of the option.", "This can be due to various factors, such as uncertainty in the market or an upcoming event that is expected to have a significant impact on the stock's price"];
  const words = ['market', 'upcoming', 'observable'];
  return <MaskedWord sentences={sentences} words={words} mdxType="MaskedWord" />;
};
export const MaskedWordsComp3 = () => {
  const sentences = ['For options sellers, high implied volatility can be attractive because it increases the likelihood that the option will expire out of the money, which means that the seller will keep the premium collected from selling the option.'];
  const words = ['high', 'out'];
  return <MaskedWord sentences={sentences} words={words} mdxType="MaskedWord" />;
};
export const MaskedWordsComp4 = () => {
  const sentences = ['Options buyers may prefer lower implied volatility because it increases the likelihood that the option will expire in the money, which means that the buyer will be able to exercise the option and potentially realize a profit.'];
  const words = ['lower', 'exercise'];
  return <MaskedWord sentences={sentences} words={words} mdxType="MaskedWord" />;
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url='https://www.youtube.com/watch?v=0_8YEglCFdU' playing controls config={{
      file: {
        attributes: {
          id: 'audio-element'
        }
      }
    }} width='320px' height='180px' mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>Seek to 00:10</button>
    </div>;
};
export const Box2 = () => <div style={{
  overflowY: 'scroll',
  maxHeight: '75vh',
  backgroundColor: 'tomato'
}}>
    {
    /* <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/videos/Theta_video_drive.mp4' /> */
  }
  <ImageAnnotation mdxType="ImageAnnotation">
  <>
      <div style={{
        overflow: 'auto',
        height: '75%',
        width: '100%'
      }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/2023/long_call_poster.png" style={{
          width: '100%'
        }} />
        <BlueLink url="https://options.21ifm.com/sofr" text="sofr" mdxType="BlueLink" />
        <LandmarkButton id={1} x={20} y={60} text="Call Option theta is a measure of the rate at which the value of a call changes as time passes, with all other factors held constant." mdxType="LandmarkButton" />
        <LandmarkButton id={2} x={65} y={60} text="This equation describes the evolution of a financial asset over time, but it includes an additional term sigma and B_t, 
, which represents the random fluctuations in the value of the asset due to a standard Brownian motion process. This equation can be used to model the evolution of an asset over time, taking into account the inherent randomness and uncertainty of the asset's value." mdxType="LandmarkButton" />
      </div>
      </>
  </ImageAnnotation>
  </div>;
export const Box3 = () => <div style={{
  width: '100%',
  height: '100%',
  padding: 20,
  backgroundColor: 'tomato'
}}>
  <Bullish_Call_Spread mdxType="Bullish_Call_Spread" />
  </div>;
export const Box4 = () => <div style={{
  overflowY: 'scroll',
  maxHeight: '75vh',
  backgroundColor: 'tomato'
}}>
  <ImageAnnotation mdxType="ImageAnnotation">
  <>
     <div style={{
        position: 'relative',
        width: '100%'
      }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/2023/long_call_poster.png" style={{
          width: '100%',
          height: 'auto'
        }} />
        <BlueLink url="https://options.21ifm.com/theta_3d" heading="Click " caption="Theta in 3D" x={14} y={91} mdxType="BlueLink" />
         <BlueLink url="https://options.21ifm.com/delta_3d" heading="Click " caption="Delta in 3D" x={44} y={91} mdxType="BlueLink" />
        <BlueLink url="https://options.21ifm.com/gamma_3d" heading="Click " caption="Gamma in 3D" x={74} y={91} mdxType="BlueLink" />
         <LandmarkButton id={1} x={18} y={27} text="Higher volatility generally increases the price of call options, as there is a greater likelihood of significant price movements in the underlying asset. This increased probability of large price movements means that the option has a higher chance of ending up in-the-money, making it more valuable." mdxType="LandmarkButton" />
        <LandmarkButton id={2} x={18} y={60} text="Call Option theta is a measure of the rate at which the value of a call changes as time passes, with all other factors held constant.
         Theta for a long call option is generally negative, meaning that the value of the option decreases as time to expiration decreases. 
         The extrinsic value of the option is largely determined by the time value component, which is impacted by theta. As time passes, the extrinsic value of the option decreases" mdxType="LandmarkButton" />
        <LandmarkButton id={3} x={46} y={60} text="Long call delta is a measure of the sensitivity of the value of a long call to changes in the price of the underlying asset. A positive delta is favorable for a long call option as it indicates that the option price is expected to increase as the underlying stock price increases" mdxType="LandmarkButton" />
        <LandmarkButton id={4} x={74} y={60} text="Long Call gamma is a measure of the rate of change of the options's delta in relation to changes in the price of the underlying asset.For a long call option, gamma is positive, which means that as the underlying stock price increases, delta is expected to increase as well. " mdxType="LandmarkButton" />
      </div>
      </>
  </ImageAnnotation>
</div>;
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const cardsData = [{
  front: {
    text: 'Card 1 Front Text',
    imageURL: 'https://image.url/image1.jpg'
  },
  back: {
    url: 'https://back.url/card1',
    text: 'Card 1 Back Text',
    mediaURL: 'https://media.url/video1.mp4'
  }
}, {
  front: {
    text: 'Card 2 Front Text',
    imageURL: 'https://image.url/image2.jpg'
  },
  back: {
    url: 'https://back.url/card2',
    text: 'Card 2 Back Text',
    mediaURL: 'https://media.url/image2.jpg'
  }
}];
export const SampleData = [{
  frontText: 'Sample Front Text',
  frontImage: 'https://sample.com/front-image.jpg',
  backUrl: 'https://sample.com/back-url',
  backText: 'Sample Back Text',
  backImageOrVideo: 'https://sample.com/back-image.jpg'
}, {
  frontText: 'Sample Front Text',
  frontImage: 'https://sample.com/front-image.jpg',
  backUrl: 'https://sample.com/back-url',
  backText: 'Sample Back Text',
  backImageOrVideo: 'https://sample.com/back-image.jpg'
}, {
  frontText: 'Sample Front Text',
  frontImage: 'https://sample.com/front-image.jpg',
  backUrl: 'https://sample.com/back-url',
  backText: 'Sample Back Text',
  backImageOrVideo: 'https://sample.com/back-image.jpg'
}];

const layoutProps = {
  opts,
MaskedWordsComp1,
DrawCanvas,
MaskedWordsComp2,
MaskedWordsComp3,
MaskedWordsComp4,
Box1,
Player,
Box2,
Box3,
Box4,
ggbParameters,
cardsData,
SampleData
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">














    <h1>{`interactive`}</h1>


    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/strategies_simulation.json" ggbParameters={ggbParameters} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[{
      component: <Box2 mdxType="Box2" />,
      button: "longcall"
    }]} mdxType="GeogebraDraw" /> 
    <br />
    <br />
    <br />
    <br />
    <SaveGeogebra mdxType="SaveGeogebra" />
    <br />
    <br />
    <br />
    <br />
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/options_strategies_db_2.json" ggbParameters={ggbParameters} appId="dashboard" email="pavanmirla@gmail.com" data={[{
      component: <Box4 mdxType="Box4" />,
      button: "longcall"
    }]} mdxType="GeogebraDraw" /> 
    <br />



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;