
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1e1e1e",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const items1 = [{
  title: 'Put-call parity: what is it?',
  subtitle: 'The value of European calls with particular exercise prices and dates can be determined from European puts with the same exercise price and date, and vice versa. '
}, {
  title: 'Why should this relationship hold true?',
  subtitle: 'If this relationship does not hold true, there will be arbitrage opportunities that will lead to inefficient markets. A market that is efficient will prevent arbitrage.'
}];
export const references = [{
  title: 'Options education',
  href: 'https://www.optionseducation.org/advancedconcepts/put-call-parity'
}, {
  title: 'Keith Cuthbertson - Derivatives Theory and Practice',
  href: 'https://www.Amazon.com'
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
Box11,
Box22,
items1,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    <h1>{`PUT CALL PARITY`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/put_call_parity/put_call_parity_intro.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>

    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Basics"}/> */
      }
    </Container>
    <br />
    <h1>{`interactive`}</h1>
 <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/put_call_parity/put_call_parity_2022_3.json" ggbParameters={ggbParameters} appId="id2" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box11 mdxType="Box11" />,
      button: "textn1"
    }, {
      component: <Box22 mdxType="Box22" />,
      button: "textn2"
    }]} mdxType="GeogebraDraw" />
    <br />

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;