import React, { useState } from 'react';
import { FaVolumeUp } from 'react-icons/fa';
import Highlighter from 'react-highlight-words';


const MaskedWord = ({ sentences, words }) => {
  const [revealed, setRevealed] = useState(Array(sentences.length).fill(false));

  const toggleReveal = index => {
    setRevealed(prevRevealed => {
      const newRevealed = [...prevRevealed];
      newRevealed[index] = !newRevealed[index];
      return newRevealed;
    });
  };

  const readAloud = sentence => {
    const utterance = new SpeechSynthesisUtterance(sentence);
    window.speechSynthesis.speak(utterance);
  };

  const maskWords = (sentence, words) => {
    const sentenceWords = sentence.split(' ');
    return sentenceWords.map(w => (words.includes(w) ? <span style={{ backgroundColor: 'yellow' }}>'****'</span> : w)).join(' ');
  };


  const colorSentences =  (sentence, words) => {


    return (
      <Highlighter
        highlightStyle={{ backgroundColor: 'yellow' }}
        highlightTag="span"
        searchWords={words}
        textToHighlight={sentence}
        style={{ color: 'gray' }}
      />
    );
  };

  const maskedSentences =  (sentence, words) => {
    const sentenceWords = sentence.split(' ');
    const sw=  sentenceWords.map(w => (words.includes(w) ? '___________' : w)).join(' ');

    return (
      <Highlighter
        highlightStyle={{ backgroundColor: '#1e1e1e' }}
        highlightTag="span"
        searchWords={["___________"]}
        textToHighlight={sw}
        style={{ color: 'gray' }}
      />
    );
  };



  return (
    <div>
      {sentences.map((sentence, index) => (
        <div key={index}>
          {revealed[index] ? (
            <p>
              {colorSentences(sentence, words)}
              &nbsp;
              &nbsp;
              <button onClick={() => toggleReveal(index)}>Hide</button>
              <button className="read-aloud-button" onClick={() => readAloud(sentence)}><FaVolumeUp /></button>
            </p>
          ) : (
            <p>
              {maskedSentences(sentence, words)}
              &nbsp;
              &nbsp;
              <button onClick={() => toggleReveal(index)}>Reveal</button>
              <button className="read-aloud-button" onClick={() => readAloud(sentence)}><FaVolumeUp /></button>
            </p>
          )}
        </div>
      ))}

    </div>
  );
};

export default MaskedWord;