
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import _ from "lodash";
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#1e1e1e",
      paper: "#1e1e1e"
    }
  }
});
export let blogs_chain = [];
export let mathTopicsJSON = [];

function TOC() {
  const [isLoading, setIsLoading] = useState(true);
  const [blogsChain, setBlogsChain] = useState([]);
  const [showTOC, setShowTOC] = useState(false);
  useEffect(() => {
    const fetchBlogs = async () => {
      const mathTopicsJSON = require("../index_levels.js").default();

      const newBlogsChain = mathTopicsJSON.filter(blog => blog.status !== 0).map(blog => ({
        category: blog.category,
        blogs: [blog]
      }));
      setBlogsChain(newBlogsChain);
      setIsLoading(false);
    };

    fetchBlogs();
  }, []);

  const handleButtonClick = () => {
    setShowTOC(!showTOC); // toggle the value of showTOC
  };

  return <ThemeProvider theme={darkTheme} mdxType="ThemeProvider">
      <Box display="flex" justifyContent="center" marginTop="1rem" mdxType="Box">
        <Box mdxType="Box">
          <Button onClick={handleButtonClick} sx={{
          width: "10rem"
        }} mdxType="Button">
            {showTOC ? "Hide TOC" : "Show TOC"}
          </Button>
          {showTOC && <div style={{
          maxWidth: "80%",
          margin: "2rem auto"
        }}>
              {blogsChain.map((level, index) => <>
                  <Card key={index} sx={{
              maxWidth: 345,
              maxHeight: 215,
              minHeight: 205,
              display: "inline-block",
              margin: "1rem "
            }} mdxType="Card">
                    <CardContent mdxType="CardContent">
                      <Typography variant="h5" mdxType="Typography">
                        {level.blogs[0].name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" mdxType="Typography">
                        {level.blogs[0].description}
                      </Typography>
                    </CardContent>
                    <CardActions mdxType="CardActions">
                      <a href={`https://options.21ifm.com/${level.blogs[0].id}`} target="_blank" rel="noopener noreferrer">
                        <Button mdxType="Button">View</Button>
                      </a>
                    </CardActions>
                  </Card>
                </>)}
            </div>}
        </Box>
      </Box>
    </ThemeProvider>;
}

const layoutProps = {
  darkTheme,
blogs_chain
};
const MDXLayout = TOC
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;