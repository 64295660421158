
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import ButtonMailto from "../../components/ButtonMailto.js";
export const items1 = [{
  title: 'What is risk of selling call options?',
  subtitle: "The seller of a short call is obliged to sell the stock at the strike price if the stock is above the strike price at expiration. If the call seller does not hold an asset to cover, he will own a short position on the asset."
}, {
  title: 'What is Initial Margin requirement?',
  subtitle: 'The Initial Margin Requirement refers to the percent of a purchase price that must be paid with cash when using a margin account. It is set by Reg T. Brokers can require more. An example is shown in the app below. '
}, {
  title: 'What is Maintenance Margin?',
  subtitle: "The maintenance margin is the amount of equity (cash and/or marginable securities) that must be kept in a margin account going forward. It is set by Reg T."
}, {
  title: 'What is Margin call? ',
  subtitle: "If account value falls below the Maintenance Requirement, your broker will require that you provide additional funds or securities otherwise your positions will be closed in order to meet the margin call."
}, {
  title: 'What are the advantages of Margin Trading?',
  subtitle: "Margin can be employed to leverage account beyond the value of one's account. "
}];
export const items2 = [{
  title: 'When selling naked options, margin requirements will change with stock movement',
  subtitle: "ITM posiiton will require have a higher margin requirment. Likewise, more volatile stocks have larger maintenance margin. Try changing the variables in the left panel of the app above"
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const FlipCard = makeShortcode("FlipCard");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Margin requirements for selling uncovered Call`}</h1>

    <h1>{`Example`}</h1>
    <br />
    <br />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/margin_call_seller/story_short_call.png
" mdxType="ImageCard" />
    <br />
    <br />
    <Container mdxType="Container">
  <Content items={items1} title={"Margin for Sold Call"} mdxType="Content" />
    </Container>
    <h1>{`INTERACTIVE`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/options_margin_template_short_call.json" ggbParameters={ggbParameters} appId="appId1margincall" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    <h1>{`QUESTION 1`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/margin_call/mc_f2.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/margin_call/33_b1.png" mdxType="FlipCard" />
    <br />
    <br />
    <h1>{`QUESTION 2`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/margin_call/volq_f.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/margin_call/volq_b.png" mdxType="FlipCard" />
    <br />
    <br />

    <Container mdxType="Container">
  <Content items={items2} title={"Margin requirement for sold Calls"} mdxType="Content" />
    </Container>

 <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: 'center'
    }} mdxType="Box">
              <ButtonMailto label="Email Me" mailto="mailto:office@21ifm.com?subject=I saw your demo app. Want to connect!&body=Thanks!" mdxType="ButtonMailto" />
  </Box>
  <Container mdxType="Container">
   <Typography variant="subtitle1" gutterBottom component="div" mdxType="Typography">
        References
      </Typography>
    <Typography variant="caption" display="block" gutterBottom mdxType="Typography">
        <br />
    <Link href="https://cdn.cboe.com/resources/options/margin_manual_april2000.pdf" mdxType="Link">
     {'CBOE Margin Manual'}
     <br />
     {'https://cdn.cboe.com/resources/options/margin_manual_april2000.pdf'}
    </Link>
    <br />
    </Typography>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;