import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51KUGSVG0QNwLjqA6vVSULu2VKX7M7UFkaga260O5QW0ViX59XA80EjTaGGY4MObTTWAB3goEntJjw3FRiZ7PHntc00t8bKLQuN"
    );
  }
  return stripePromise;
};
export default initializeStripe;
