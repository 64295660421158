import React from 'react';
import Modal from 'react-modal';
import Draggable from 'react-draggable';

const handleDragStop = (e) => {
  e.stopPropagation();
  // drag logic
};

Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: 50,
    zIndex: 100000,
  },
  content: {
    position: 'fixed',
    top: 50,
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '90vw',
    maxHeight: '60vh',
    backgroundColor: '#1a1a1a',
    border: 'none',
    borderRadius: '8px',
    padding: '16px',
    zIndex: 100000000000,
    '@media (max-width: 768px)': {
      width: '80%',
    },
  }
};

const MyDraggableComponent = (props) => {
  const { setIsModalOpen } = props;
  return (
    <Modal
      isOpen={true}
      onRequestClose={() => setIsModalOpen(false)}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
    >
        <div onClick={handleDragStop} style={{ overflowY: 'auto', maxHeight: 'calc(60vh )' }}>
          {props.children}
        </div>
      <button
        style={{
          padding: '5px 10px',
          border: 'none',
          borderRadius: '5px',
          position: 'absolute',
          top: 5,
          right: 5,
        }}
        onClick={() => setIsModalOpen(false)}
      >
        Close
      </button>
    </Modal>
  );
};

export default MyDraggableComponent;
