import React from 'react';

class TopicCard extends React.Component {
  render() {
    return (
      <div className="card">
        <h3>{this.props.title}</h3>
        <p>{this.props.description}</p>
      </div>
    );
  }
}

class Line extends React.Component {
  render() {
    return <div className="line" />;
  }
}

class LearningPath extends React.Component {
  render() {
    return (
      <div className="learning-path">
        <TopicCard title="Introduction to React" description="Learn the basics of React, including JSX, components, state, and props." />
        <Line />
        <TopicCard title="Intermediate React" description="Learn intermediate React concepts such as handling events, working with forms, and state management." />
        <Line />
        <TopicCard title="Advanced React" description="Learn advanced React topics such as performance optimization, server-side rendering, and building real-world applications." />
      </div>
    );
  }
}

export default LearningPath;