
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import { useState } from 'react'
import Alert from '@mui/material/Alert';
import data from '../../data/tulip_spot_trade.json';
import GeogebraComponent from "../../components/GeogebraComponent";
import VideoSection from "../../components/VideoSection";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const short_position = data.data;
export const short_position_params = {
  // material_id: "",
  width: 1800,
  height: 900,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  ggbBase64: short_position
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const Highlight = ({
  children,
  color
}) => <span style={{
  backgroundColor: color,
  borderRadius: '2px',
  color: '#fff',
  padding: '0.2rem'
}}>
    {children}
  </span>;
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'Why would someone buy a put option?  ',
  subtitle: "A trader buys a put based on the expectation of share price decline. This locks in a sell price. If share price does not decrease, the maximum loss will be limited to the cost of the put."
}, {
  title: 'Describe intrinsic value of a put option',
  subtitle: "A put option is in-the-money if share price is lower than the put's strike price. In other words, the put holder can sell the stock at a higher price than the current market price. The intrinsic value of the put is the amount by which the stock is below the strike price."
}, {
  title: 'What does it mean to be out-of-the-money?',
  subtitle: "Out-of-the-money means that share price is greater than the put's strike price. A put owner will have no incentive to exercise because the stock can be sold at a higher price. If this occurs at expiration, the put will be worthless."
}];
export const items2 = [{
  title: 'Who is a typical put seller? ',
  subtitle: "Put sellers typically seek to lock in a stock purchase price at the strike price. "
}, {
  title: 'What is the difference between a covered put, cash secured put and a naked put?',
  subtitle: "A covered put is short stock + short put. Short put + $$$$ = cash secured put. A naked put is when a put option is sold without having any offsetting positions "
}, {
  title: 'What is difference between assignment and exercise? ',
  subtitle: "If a put holder exercises his right, he sell the shares at the strike price. The assigned put seller must buy 100 shares. The broker will deliver them to the sell and transfer the cash to the buyer."
}];
export const exercise_items = [{
  title: 'What is meant by exercising a put option?',
  subtitle: "Exercise is the action taken by put holder to sell 100 shares of the stock at 100 times the strike price."
}, {
  title: 'When will put holder exercise his put?',
  subtitle: 'If the premium for the put option in the market is higher than the price at which the put was bought. If the time premium remains, one would sell to close (STC) the put'
}, {
  title: 'Can the put holder sell the put before it expires?',
  subtitle: 'Put holder can sell at any time. '
}, {
  title: 'Who coordinates the exercise of options and delivery of stock?',
  subtitle: "The Option Clearing Corporation coordinate exercise of options. The brokers coordinate delivery of cash and stock."
}, {
  title: 'What is automatic exercise?',
  subtitle: "In-the-money (ITM) options at expiration will be automatically exercised by the Option Clearing Corp (OCC) whether they are long or short. This is called Exercise by Exception. For equity options, you will end up with a long or short position in the underlying (index options are cash settled). The owner of an option can avoid automatic exercise if he submits a do not exercise order to his broker."
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const ContentMedia = makeShortcode("ContentMedia");
const Intro = makeShortcode("Intro");
const Link = makeShortcode("Link");
const layoutProps = {
  opts,
short_position_params,
meta,
Highlight,
ggbParameters,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
items2,
exercise_items
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    <h1>{`what is a put option?`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <p>{` A put option gives the owner the right, but not the obligation, to sell the underlying asset
at a specified price any time until a specified date known as expiration. `}</p>
      <p>{`The put buyer can:`}</p>
      <p>{`1 : Speculate on the downward direction of stock`}</p>
      <p>{`2 : Hedge a long position in underlying or another option`}</p>
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/put_home.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>
    <h1>{`Insurance as a protective put`}</h1>
    <Container mdxType="Container">
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/protective_put_v1.png
" mdxType="ImageCard" />
    </Container>
    <Container mdxType="Container">
      <ContentMedia title="Scenarios at end of the year" mdxType="ContentMedia">
 <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/protective_put_v2.png
" mdxType="ImageCard" />
      </ContentMedia>
    </Container>
    {
      /* "https://storage.googleapis.com/chapters-ifm/testfolder/2022/DELTA/What_are_put_options.mp4" */
    }
    <br />
    <br />
    <h1>{`Put intrinsic value and profit graphs`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Put Buyer"} mdxType="Content" />
    </Container>

    <Container mdxType="Container">
  <Content items={items2} title={"Put Seller"} mdxType="Content" />
    </Container>
    <h1>{`Interactive 1`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/risk_graph_puts_4.json" ggbParameters={ggbParameters} appId="parity_graph_put" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    <h1>{`DEFINE PUT EXERCISE`}</h1>
    <Container mdxType="Container">
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/puts_slide.png" mdxType="ImageCard" />
      <br />
    </Container>

    <Container mdxType="Container">
  <Content items={exercise_items} title={""} mdxType="Content" />
    </Container>
    <br />
    <br />
    <h1>{`Interactive 2`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/put_long_short_3.json" ggbParameters={ggbParameters} appId="second_app" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    <br />
    <h1>{`Related Topics`}</h1>
    <br />
    <br />s
    <h1>{`1. SHORT PUT MARGIN REQUIREMENTS`}</h1>
    <Container mdxType="Container">
      <Link href="http://options.21ifm.com/margin_put_seller" target="_blank" mdxType="Link">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/margin_link_gif.gif
" mdxType="ImageCard" />
      </Link>
    </Container>
    <h1>{`2. LONG AND SHORT PUT  GREEKS`}</h1>
    <Container mdxType="Container">
      <Link href="http://options.21ifm.com/all_greeks_put" target="_blank" mdxType="Link">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/greeks_link_gif.gif
" mdxType="ImageCard" />
      </Link>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;