import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase";

import { firebaseAuth } from "../provider/AuthProvider";
import Link from "@material-ui/core/Link";
import { useTheme } from "@mui/material/styles";
import ButtonMailto from "./ButtonMailto.js";
import Box from "@material-ui/core/Box";

export default function IfPaidUser(props) {
  const fromContext = useContext(firebaseAuth);

  const { token, handleSignout, inputs, currentUser , setCurrentUser, setPaymentFlag,paymentFlag, currentUserId, SetCurrentUserId } = useContext(firebaseAuth);

  const theme = useTheme();

  useEffect(() => {

    firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {        
        alert(paymentFlag)
        // setPaymentFlag(window.localStorage.getItem('paymentFlag')
      } else {
        setCurrentUser(null);
      }
    })

  }, []);


  return (
    <>
      <h2> paymentFlag {paymentFlag}</h2>
       <div>{JSON.stringify(currentUser)}</div>
      { paymentFlag ? (
        <>{props.children}</>
      ) : (
        <>
          <h2>
            paymentFlag {paymentFlag} Current user: {currentUser}{" "}
            {window.localStorage.getItem("paymentFlag")}
          </h2>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonMailto
              label="Grant me Access"
              mailto="mailto:office@21ifm.com?subject=I want to checkout Calls Vega App.!&body=Thanks!"
            />
          </Box>
        </>
      )}
    </>
  );
}
