
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: "100%",
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const GeogebraDraw = makeShortcode("GeogebraDraw");
const layoutProps = {
  ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/call_question_2.json" ggbParameters={ggbParameters} appId="appId1OutRatioBS" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;