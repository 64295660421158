import styled from 'styled-components'


const Thing = styled.span.attrs((/* props */) => ({ tabIndex: 0 }))`
  color: lightgray;

  &:hover {
    color: yellow; // <Thing> when hovered
  }



  &.something {
    background: orange; // <Thing> tagged with an additional CSS class ".something"
  }

  .something-else & {
    border: 1px solid; // <Thing> inside another element labeled ".something-else"
  }


  &.thick {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: yellow;
    text-decoration-thickness: 5px;
  }

  
`
export default Thing;