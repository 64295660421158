import React from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

const ImageCard = (props) => {
    return (
    <Grid container justifyContent="center" alignItems="center" >
       <Box
        component="img"
        sx={{
          height: '70%',
          width: '70%',
          minWidth: {xs: '100%', md: '60%'},
          minHeight: {xs: '100%', md: '60%'},
        }}
        alt="A figure."
        src={props.img}
      />
   </Grid>
    );
};

export default ImageCard;