
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import ButtonMailto from "../../components/ButtonMailto.js";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
export const items1 = [{
  title: 'Why to use log returns?',
  subtitle: "log-normal returns are frequently used in finance due to their statistical properties and computational efficiency. A product of two normally distributed variables is not normal, while a sum of normally distributed variables is normal. Rather than multiplying, logarithms replace multiplication with addition. "
}];
export const items2 = [{
  title: 'Product of two normal variables is not normal ?',
  subtitle: "The theory suggests that the product of two random variables is not normally distributed. Why is this? Examine. Tip: The distribution of two normally distributed variables is skewed. Because statistical tools can be applied to normal distributions, it makes sense to analyze normal distribution variables."
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const Link = makeShortcode("Link");
const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Why log returns?`}</h1>
    <br />
    <br />

    <Container mdxType="Container">
  <Content items={items1} title={"Introduction"} mdxType="Content" />
    </Container>
    <br />
    <br />
    <h1>{`Growth rate`}</h1>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/log_returns/growth_rates_story.png
" mdxType="ImageCard" />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/log_returns/log-story_1.png
" mdxType="ImageCard" />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/log_returns/log-story_2.png
" mdxType="ImageCard" />
    <br />
    <br />
    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/why_log_normal.mp4"} mdxType="VideoSection" />
    <h1>{`Interactive 1`}</h1>
    <br />
    <br />
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/log_returns/log_returns.json" ggbParameters={ggbParameters} appId="appId2CallLongShort" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    {
      /* jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/BASICS/random_and_random.json" */
    }

    <Container mdxType="Container">
  {
        /* <Content items={items2} title={"Explainer Highlights"}/> */
      }
    </Container>

  <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: 'center'
    }} mdxType="Box">
              <ButtonMailto label="Write me an E-Mail" mailto="mailto:office@21ifm.com" mdxType="ButtonMailto" />
  </Box>
    <br />
    <br />s
    <h1>{`Related topics`}</h1>
    <Container mdxType="Container">
      <Link href="http://options.21ifm.com/lognormal_normal" target="_blank" mdxType="Link">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/BASICS/trailer_log_normal.png
" mdxType="ImageCard" />
      </Link>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;