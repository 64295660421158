
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import SimpleAccordion from "../../components/SimpleAccordian";
import IfPaidUser from "../../components/IfPaidUser";
import useFetchData from "../../components/UseFetchData";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ReactPlayerComp from "../../components/ReactPlayerComp";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import ImageAnnotation from "../../components/ImageAnnotation";
import "../../components/style.css";
import Marker from '../../components/ImageMarkers';
import LandmarkButton from '../../components/LandmarkButton';
import GRCard from "../../components/GRCard";
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const items1 = [{
  title: 'Which distribution should be used to model asset prices?',
  subtitle: 'Log Normal distribution. Since the lognormal distribution is bound by zero on the lower side, it is perfect for modeling asset prices that cannot take negative values.'
}, {
  title: 'How is uncertainity in future prices modelled?',
  subtitle: 'Uncertainty is modelled by a probability density function.Its mean is assumed to approximate expected future price.'
}, {
  title: 'What is the required rate of return?',
  subtitle: "The required rate of return (RRR) is the minimum return an investor will accept for owning a company's stock, as compensation for a given level of risk associated with holding the stock."
}, {
  title: 'How do you calculate present value?',
  subtitle: 'Present value (PV) is the current value of a future sum of money or stream of cash flows given a specified rate of return. Future cash flows are discounted at the discount rate, and the higher the discount rate, the lower the present value of the future cash flows'
}];
export const items2 = [{
  title: 'Pricing financial assets',
  subtitle: 'Investors use present value to determine whether the price they pay for an investment is reasonable'
}, {
  title: 'Discount rates can be confusing.',
  subtitle: ' Most people immediately grasp the concept of compound growth. Discounting is merely the inverse of growing.'
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  Box1,
Text9,
Text14,
items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <h1>{`BASIC MATHEMATICS TO UNDERSTANF BLACK SCHOLES OPTIONS PRICING`}</h1>
    <Container mdxType="Container">
      <ImageAnnotation mdxType="ImageAnnotation">
  <>
     <div style={{
            position: 'relative',
            width: '100%'
          }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/const_fluc2.png" style={{
              width: '100%',
              height: 'auto'
            }} />
        
        {
              /* <Marker left="25%" top="5%"  mleft="50%" mtop="25%"   text="1" >
               <p>Modal content goes here...</p>
               <Player/> 
              </Marker>
              <Marker left="5%" top="25%"  mleft="50%" mtop="25%"  text="2" >
               <p>Modal content goes here...</p>
              </Marker>
              <Marker left="75%" top="55%" mleft="20%" mtop="20%"  text="3" >
               <p>Modal content goes here...</p>
              </Marker> */
            }
        <LandmarkButton id={1} x={20} y={60} text=" This equation  describes the evolution of a financial asset over time, where S_t represents the value of the asset at time t, S_0 represents the initial value of the asset at time 0, and r represents the growth rate of the asset. This equation can be used to model the expected evolution of an asset over time, assuming that it grows at a constant rate without any random fluctuations" mdxType="LandmarkButton" />
        <LandmarkButton id={2} x={65} y={60} text="This equation describes the evolution of a financial asset over time, but it includes an additional term sigma and B_t, 
, which represents the random fluctuations in the value of the asset due to a standard Brownian motion process. This equation can be used to model the evolution of an asset over time, taking into account the inherent randomness and uncertainty of the asset's value." mdxType="LandmarkButton" />
      </div>
      </>
      </ImageAnnotation>
    </Container>
    <Container mdxType="Container">
      <ImageAnnotation mdxType="ImageAnnotation">
  <>
     <div style={{
            position: 'relative',
            width: '100%'
          }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/brownian_geometric_vs.png" style={{
              width: '100%',
              height: 'auto'
            }} />
        
        {
              /* <Marker left="25%" top="5%"  mleft="50%" mtop="25%"   text="1" >
               <p>Modal content goes here...</p>
               <Player/> 
              </Marker>
              <Marker left="5%" top="25%"  mleft="50%" mtop="25%"  text="2" >
               <p>Modal content goes here...</p>
              </Marker>
              <Marker left="75%" top="55%" mleft="20%" mtop="20%"  text="3" >
               <p>Modal content goes here...</p>
              </Marker> */
            }
        <LandmarkButton id={1} x={20} y={35} text="Mathematically, standard Brownian motion can be described using the concept of a random walk, which is a mathematical model for a sequence of random steps. t represents the length of time that has passed and Z is a random variable with a standard normal distribution (mean of 0 and standard deviation of 1)" mdxType="LandmarkButton" />
        <LandmarkButton id={2} x={85} y={35} text="Geometric brownian motion Is a process that models the evolution of a system over time based on the assumption that the system undergoes exponential growth or decay.This equation describes how the value of the asset changes over time. " mdxType="LandmarkButton" />
      </div>
      </>
      </ImageAnnotation>
    </Container>
    <Container mdxType="Container">
      <ImageAnnotation mdxType="ImageAnnotation">
  <>
     <div style={{
            position: 'relative',
            width: '100%'
          }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/brownian_motion_code_2.png" style={{
              width: '100%',
              height: 'auto'
            }} />
        
        {
              /* <Marker left="25%" top="5%"  mleft="50%" mtop="25%"   text="1" >
               <p>Modal content goes here...</p>
               <Player/> 
              </Marker>
              <Marker left="5%" top="25%"  mleft="50%" mtop="25%"  text="2" >
               <p>Modal content goes here...</p>
              </Marker>
              <Marker left="75%" top="55%" mleft="20%" mtop="20%"  text="3" >
               <p>Modal content goes here...</p>
              </Marker> */
            }
        <LandmarkButton id={1} x={4} y={25} text="r s_t dt represents drift of the asset price, which reflects the overall direction of the price movement." mdxType="LandmarkButton" />
        <LandmarkButton id={2} x={35} y={25} text=" product of sigma S_t and small change in brownian process dB_t, represents the random diffusion of the asset price, which reflects the random fluctuations in the price due to various factors such as market demand, supply, and investor sentiment " mdxType="LandmarkButton" />
      </div>
      </>
      </ImageAnnotation>
    </Container>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`Interactive`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/Wiener_Process_simulated_4.json" ggbParameters={ggbParameters} appId="wiener" email="pavanmirla@gmail.com" data={[{
      component: <Text14 mdxType="Text14" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }, {
      component: <Text14 mdxType="Text14" />,
      button: "f"
    }]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Basics"}/> */
      }
    </Container>
    <br />
    {
      /* sonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/pf2.json" */
    }

    <Container mdxType="Container">
  {
        /* <Content items={items2} title={"Where and how can I use this?"}/> */
      }
    </Container>
    <br />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;