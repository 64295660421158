
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  // borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'How to setup Long Straddle?',
  subtitle: 'The straddle purchase consists of buying both a put and a call based on the same terms - at-the-money strikes, the same underlying stock, the same strike price, and the same expiration date.'
}, {
  title: 'How much does it cost to setup?',
  subtitle: 'In a long straddle, we are buying options, so initial cash flow is negative - a debit option strategy.'
}, {
  title: 'How do you calculate Max Profit?',
  subtitle: 'The maximum profit from a long straddle position is unlimited if there is no upside limit to where the underlying price can go.'
}, {
  title: 'How do you calculate Max Loss?',
  subtitle: 'A long straddle has only long options, not short ones, so at expiration there is no assignment risk. Losses are limited to the premium paid.'
}, {
  title: 'How do stock price changes affect profitability?',
  subtitle: 'A long straddle has only long options, not short ones, so at expiration there is no assignment risk. Losses are limited to the premium paid.'
}, {
  title: ' What is the impact of time on profitability?',
  subtitle: 'Over time, the position loses value. Due to negative theta. See the interactive below for more information'
}, {
  title: ' What is the impact of volaitlity on profitability?',
  subtitle: 'Long option positions gain value as volatility increases. As a straddle is composed of two long positions, its value increases as volatility rises'
}];
export const items2 = [{
  title: 'What is a Iron Condor Startegy',
  subtitle: 'Iron Condor is a strategy that collects premium by using four options in a high volatility environment.The trade will be profitable if the stock remains within a defined range or volatility drops'
}];
export const references = [{
  title: 'How to setup Long Straddle?',
  link: "https://www.reddit.com/r/Questrade/comments/gexhl7/how_does_questrade_calculate_the_maintain_excess/"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Container = makeShortcode("Container");
const UnderLine = makeShortcode("UnderLine");
const ImageCard = makeShortcode("ImageCard");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
items2,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">











    <h1>{`Long Straddle`}</h1>
    <Container mdxType="Container">
      <p>{`Long straddle is an option strategy with two long positions. One put and one call with the same strike. They are both `}<UnderLine className="thick" mdxType="UnderLine">{`at-the-money`}</UnderLine>{`.`}</p>
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/RATIOS/debit_straddle_new_home.png" mdxType="ImageCard" />
      <br />
      <br />
      <p>{`Long straddle is used to profit from  `}<UnderLine className="thick" mdxType="UnderLine">{`substantial price movements`}</UnderLine>{`, regardless of the direction of those movements. You would use this strategy when you have a good feeling that the price of a security will move significantly in one direction or another, but cannot predict which way it will move.`}</p>
    </Container>

    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Learning Objectives"}/> */
      }
    </Container>
    {
      /* jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/straddle_2022_template.json" */
    }

    <h1>{`interactive`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/straddle_debit/spreads_template_straddle_debit_4.json" ggbParameters={ggbParameters} appId="id2straddle1" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;