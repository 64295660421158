import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from '@mui/material/Link';

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography color="lightgray" fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            key={i}
            // padding={1}
            // marginBottom={i === item.length - 1 ? 0 : 1}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            // backgroundColor: "#373737",
            color: "#90caf9"
            }}
          >
            <Box
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              {/* <Typography fontWeight={200}>{item.title}</Typography> */}
              <Link href={item.link} target="_blank" rel="noopener"> {item.title} </Link>
            </Box>
            {/* <AccordionDetails>
              <Typography color="lightgray">{item.subtitle}</Typography>
            </AccordionDetails> */}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const References = (props) => {
  return (
    <Box>
      <Box marginBottom={6} >
        <FaqGroupItem
          title={props.title}
          items={props.items}
        />
      </Box>
   
    
    </Box>
  );
};

export default References;
