import React from "react";
import useFetchData from "./UseFetchData";
import GeogebraComponent from "./GeogebraComponent";
import Skeleton from '@material-ui/lab/Skeleton';
import MyLoader from "./MyLoader";

const GetGGBJson = ({ jsonUrl, ggbParameters, appId,stateChanger,onClickHandle, fullWidth }) => {
  const [{ data, isLoading, isError }] = useFetchData({
    initialFetchUrl: jsonUrl
  });
  if (!isLoading)
    if (typeof data.res !== "undefined") {
      var ggbBase64 = data.res;
      var updatedParameters = {
        ...ggbParameters,
        ggbBase64
      };
    }

  return (
    <>
      {isError && <p>Something went wrong...</p>}
      {isLoading ? (
        <div>
          <MyLoader/>
        </div>
      ) : (
        <>
          <GeogebraComponent
            count={0}
            fullWidth={fullWidth || false}
            id={appId}
            newParameters={updatedParameters}
            stateChanger={stateChanger}
            onClickHandle={onClickHandle}
          />
        </>
      )}
    </>
  );
};

export default GetGGBJson;
