
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import ButtonMailto from "../../components/ButtonMailto.js";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Normal and Log Normal distributions`}</h1>
    <GeogebraDraw jsonUrl=" https://storage.googleapis.com/chapters-ifm/testfolder/math/normalormal_relationship.json" ggbParameters={ggbParameters} appId="log_id" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    <h1>{`log is inverse of exponent`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/BASICS/exponential_log.json" ggbParameters={ggbParameters} appId="log_exp" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    {
      /* //power_functions.ggb */
    }

  <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: 'center'
    }} mdxType="Box">
              <ButtonMailto label="Ask a question" mailto="mailto:office@21ifm.com" mdxType="ButtonMailto" />
  </Box>
    <br />
    <br />
    <br />
    <br />
    <h1>{`Additive rule with logarithms`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/logarithm_natural_log_5.json" ggbParameters={ggbParameters} appId="appId2" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;