
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import GridList from "../../components/GridList";
import ButtonMailto from "../../components/ButtonMailto.js";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Button from '@mui/material/Button';
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import Link from '@mui/material/Link';
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters_quiz = {
  // material_id: "",
  // width: "1960",
  // height: "100%",
  borderColor: "black",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: "100%",
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <>
    <div style={{
    padding: 20,
    color: 'gray',
    display: "flex",
    margin: "auto",
    width: "30%"
  }}>
      Each step includes interactive explainers and videos.
  </div>
  <div style={{
    padding: 20,
    backgroundColor: 'tomato',
    display: "flex",
    margin: "auto",
    width: "30%"
  }}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/IronCondorStep1.mp4' mdxType="ReactPlayer" />
  </div>
  </>;
export const Video0 = () => <>
  <div style={{
    padding: 20,
    backgroundColor: 'tomato'
  }}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_condor/video0_ic.mp4' mdxType="ReactPlayer" />
  </div>
  </>;
export const Video1 = () => <>
  <div style={{
    padding: 20,
    backgroundColor: 'tomato'
  }}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_condor/video1_ic.mp4' mdxType="ReactPlayer" />
  </div>
  </>;
export const Video2 = () => <>
  <div style={{
    padding: 20,
    backgroundColor: 'tomato'
  }}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_condor/video2_ic_1.mp4' mdxType="ReactPlayer" />
  </div>
  </>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'What is a Short Iron Condor?',
  subtitle: 'A short Iron Condor is a strategy that collects premium by using four options in a high volatility environment. The trade will be profitable if the stock remains within a defined range and/or volatility drops'
}, {
  title: 'What makes it a credit strategy?',
  subtitle: 'The trader will collect a premium. As premium decays, Short Iron Condor profits. Premium decays in general for options over time and with lesser volatility. These situations, passage of time and falling volatility are hence in Credit Iron Condor favor  '
}, {
  title: 'What happens if price remains side ways and time decays?',
  subtitle: 'The most profit is made when a stock settles between strikes inner strikes at maturity. where all options expire worthless. Premium collected is the net profit.'
}, {
  title: 'When to open Iron Condor?',
  subtitle: 'Ideally when IV is high and the stock directional forecast is sideways movement'
}];
export const theta = [{
  title: 'Is theta of Iron Condor always positive over time?',
  subtitle: 'No. Theta of Iron Condors can be positive or negative. It is postive between inner strikes. Slightly negative over the wings'
}, {
  title: 'How does positive theta help Iron Condor?',
  subtitle: 'In step2, observe that Theta is concave, that is net positive as long as it remains in the profit range. Inner options are short. As short options have positive theta, this combination with Iron Condor has a net positive theta'
}];
export const gamma = [{
  title: 'What is Gamma Risk?',
  subtitle: 'Higher gamma means a traders options have a greater sensitivity to changes in the underlying'
}, {
  title: 'Is Gamma of Iron Condor always positive over time?',
  subtitle: 'No. Gamma of Iron Condors can be positive or negative. It is negative between inner strikes. Slightly positive over the wings'
}, {
  title: 'Is Gamma of Iron Condor always positive over time?',
  subtitle: 'No. Gamma of Iron Condors can be positive or negative. It is negative between inner strikes. Slightly positive over the wings'
}, {
  title: 'What happens when stock moves close to the short option?',
  subtitle: 'Gamma rises for the short options, when stock is near short strike. This is offer by the long positons increasing gamma as stock approaches short strike. This gets dramatic though when the option is close to maturity. the gamma of the short strike explodes, Long strike does not provide offseting gamma. So it is better to close the positon before gamma explosion happens close to maturity .'
}];
export const vega = [{
  title: 'Why is vega net negative for Iron Condor?',
  subtitle: 'Since inner strikes are short positions, and their vega is negative, Iron Condor is net negative vega. Meaning that pnL for Iron Condor and vega ar enegatively correlated. Increase in vega would have negative impact on the strategy. Remember, for Iron Condor, trader wishes from premium decay. Not premium appreciation'
}];
export const items2 = [{
  title: 'What is impact of stock movement?',
  subtitle: 'Delta of the strategy remains close to Zero. It is a delta neutral strategy by design. Remember that Time decay and Volatility drop will allow the trade to be closed at a cheaper price than the credit received.Hence making a profit in such scenario '
}, {
  title: 'Is it posisble to see impact of volatility, time and stock price in 3d for Iron Condor?',
  subtitle: 'Yes. See the App following next.'
}, {
  title: 'What is effect of wider wings?',
  subtitle: 'Wider spreads will increase the collected premium upfront and gives more leeway to manage IC. Wider the spreads lesser the impact of theta and vega from the long call and put positions '
}];
export const related = [{
  title: 'Iron condor profile over time',
  subtitle: 'Iron Condor profile in the figure above shows, how the payoff as a function of stock price is flat to begin with. But it gradually peaks as the time approaches maturity'
}, {
  title: 'Strangles and vertical spreads',
  subtitle: 'Refer to sections on strangles and spreads since Iron Condor is sort of an extension of stranges and vertical spreads'
}];
export const references = [{
  title: 'Keith Cuthbertson, Dick Nitzsche,Niall Sillivan - Derivatives theory and Practice',
  link: 'https://www.waterstones.com/book/derivatives/keith-cuthbertson/dirk-nitzsche/9781119595595'
}, {
  title: 'How to Calculate Options Prices and Their Greeks - Pierino Ursone',
  link: 'https://www.pierinoursone.com/introduction'
}, {
  title: "Collecting Nickels i9n front of a StreamRoller",
  link: "https://earlyretirementnow.com/2019/09/11/ubs-another-option-strategy-failure/amp/"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const FlipCard = makeShortcode("FlipCard");
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters_quiz,
ggbParameters,
opts,
Box1,
Intro,
Video0,
Video1,
Video2,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
theta,
gamma,
vega,
items2,
related,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">














    {
      /* 
      # Credit Condor Explainer
       <GeogebraDraw 
         jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/credit_condor_2022_2.json"
         ggbParameters={ggbParameters}
         appId="id2"
         email="pavanmirla@gmail.com"
         data= {[
           { component: <Intro/>, button: "text11" },
           {component: <Text4/>, button:  "text3"}
         ]}
       />
      
      # Credit Condor Greeks
      
       <GeogebraDraw 
         jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/volatality_short_condor.json"
         ggbParameters={ggbParameters}
         appId="id1"
         email="pavanmirla@gmail.com"
         data= {[
           { component: <Intro/>, button: "text11" },
           {component: <Text4/>, button:  "text3"}
         ]}
       /> */
    }
    <h1>{`Credit IRON Condor`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/RATIOS/credit_iron_condor_new_home.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>
    <h1>{`setup`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/iron_condor_setup_1.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>

    <Container mdxType="Container">
  <Content items={items1} title={"Introduction"} mdxType="Content" />
    </Container>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/short_condor/spreads_template_iron_condor.json" ggbParameters={ggbParameters} appId="id3" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  {
        /* <Content items={theta} title={"Theta of Iron Condors"}/> */
      }
    </Container>

    <Container mdxType="Container">
  {
        /* <Content items={gamma} title={"Gamma of Iron Condors"}/> */
      }
    </Container>

    <Container mdxType="Container">
  {
        /* <Content items={theta} title={"Vega of Iron Condors"}/> */
      }
    </Container>

    <Container mdxType="Container">
  {
        /* <Content items={items2} title={"Questions"}/> */
      }
    </Container>
    <h1>{`QUESTION1`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/short_condor_drag.json" ggbParameters={ggbParameters_quiz} appId="id_quiz_condor" email="pavanmirla@gmail.com" data={[{
      component: <Video0 mdxType="Video0" />,
      button: "video0"
    }, {
      component: <Video1 mdxType="Video1" />,
      button: "video1"
    }, {
      component: <Video2 mdxType="Video2" />,
      button: "video2"
    }]} mdxType="GeogebraDraw" />
    <br />
    <h1>{`QUESTION 2`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/ic_questions/5.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/ic_questions/6.png" mdxType="FlipCard" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`QUESTION 3`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/ic_questions/7.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/ic_questions/8.png" mdxType="FlipCard" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`QUESTION 4`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/ic_questions/9.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/ic_questions/10.png" mdxType="FlipCard" />
    <br />
    <br />
    <br />
    <br />
    <br />

    <Container mdxType="Container">
  {
        /* <Content items={related} title={"Overview of explainer"}/> */
      }
    </Container>
    {
      /* <Box sx={{ 
                   display: "flex" ,
                   alignItems: "center",
                   justifyContent: 'center'
                 }}>
                   <ButtonMailto label="Connect" mailto="mailto:office@21ifm.com?subject=I saw your demo app. Want to connect!&body=Thanks!" />
      </Box> */
    }

    <Container mdxType="Container">
  <References items={references} title={"references"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;