// add useContext
import React, { useContext, useState, useEffect } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import firebase from "firebase";
import { Signup } from "./SignUp";
import {
  useHistory
} from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ButtonMailto from "./ButtonMailto.js";


import SimpleAccordion from "./SimpleAccordian";
import { alertTitleClasses, getAlertTitleUtilityClass } from "@mui/material";


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    },
  },
  image: {
    backgroundImage: 'url(https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/logo_ifm.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(4, 1),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));


const Signin = () => {
  const classes = useStyles();
  const history = useHistory()
  const {
    handleSignin,
    handleSigninEmailLink,
    inputs,
    setInputs,
    errors,
    token,
    handlesigninGoogle,
    handleSigninGoogleAfterRedirect
  } = useContext(firebaseAuth);




  useEffect(() => {
    return firebase.auth().onAuthStateChanged((im) => {
      if (im) {
        handleSigninGoogleAfterRedirect(im)
        // history.push("/")
        // Redirect the user to previous location
        const previousLocation = window.localStorage.getItem("previousLocation");
       
        if (previousLocation) {
          // Redirect the user to previous location
          history.push(previousLocation);
        } else {
          // Redirect the user to home page
          history.push('/');
        }
       

      } else {
        // setIsInitialized(true);
        // setUser(null);
        // alert("false")
        history.push("/buyclick")
      }
    });
  }, []);


  const delay = ms => new Promise(res => setTimeout(res, ms));
  
  const handleSubmit =async (e) => {
    e.preventDefault();
    await handleSignin();
    await delay(2000)

    if(window.localStorage.getItem('token') !== null)
     history.push("/")
     else
     history.push("/forgotPassword")

    //  errors.length > 0
    //          ? errors.map((error) => (

    //         ))
    //       :null

  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(inputs);
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitEmailLink = (e) => {
    e.preventDefault();
    console.log("handleSubmitEmailLink");
    handleSigninEmailLink();
  };

  const Toggle = (props) => {
    const [show, toggleShow] = React.useState(false);
  
    return (
      <div>  
        <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={() => toggleShow(!show)}
            >
              Login with your own email address
            </Button>
            <br/>
        {show && props.children}
      </div>
    )
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} > 
      <div style={{display: "flex", textAlign: "center", justifyContent: "center"}}>
        <img style={{margin: "0 auto;"}} src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/logo_ifm.png"/>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>


        <div className={classes.paper}>
     

          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
      
          <Typography component="h1" variant="h5">
             Step1: Sign in
          </Typography>
          <br/>
          <br/>
      
      <div style={{display: 'flex', textAlign: 'center'}}>
        Signin Required before payment. Then will be redirected to home page with payment instructions
              <br/>

              <br/>
      </div>

          <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handlesigninGoogle}
            >
              Google Sign in / Sign up
            </Button>
           
       
            <Typography component="h1" variant="h5">
            or
          </Typography>

          <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={event =>  window.location.href='/signinemail'}
            >
              Login / Signup with your email
            </Button>


            <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        position: 'absolute',
                        top: '10%',
                        left: '20%',
                        width: '100px'
                      }}
                    >
                      <ButtonMailto
                        label="Email"
                        mailto="mailto:office@21ifm.com?subject=Sign in question!&body=Thanks!"
                      />
          </Box>
          </div>

          <div className={classes.paper}>
      <Typography component="h1" variant="h5">
            Step2: 
          </Typography>
         <Link href="https://options.21ifm.com" variant="body2">
                  {"Will be redirected to Payment link"}
        </Link>

      </div>

        
      </Grid>
    </Grid>
  );
};

export default Signin;