
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const data = [{
  title: "1.Call price simulation",
  id: 1,
  script: 'Execute({"RunClickScript(scenarioB)"})',
  text: "How does Call value change with change in underlying price?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}, {
  title: "2.Call price derivation",
  id: 2,
  script: 'Execute({"RunClickScript(formulasB)"})',
  text: "Derive Call price from Put Call parity ",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}, {
  title: "3.Intrinsic value",
  id: 3,
  script: 'Execute({"RunClickScript(intextB)"})',
  text: "What factors influence Options Intrinsuic value?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}, {
  title: "4.Moneyness of Option",
  id: 4,
  script: 'Execute({"RunClickScript(itmB)"})',
  text: "When is Call option ITM, ATM and OTM",
  image: "",
  video: "https://www.youtube.com/embed/88eG8-3qpds"
}, {
  title: "5.Strike price, Asignment and exercise",
  id: 5,
  script: 'Execute({"RunClickScript(excB)"})',
  text: "Rights and Obligations of Call options seller and buyer",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}];
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const items1 = [{
  title: 'Why does the payoff of a call before expiration look like a curve?',
  subtitle: "Black Scholes' formula determines the option premium based on several factors, including volatility, time, interest rates, and stock price. It captures the nonlinear relationship between premium and stock price. As a result, the payoff looks like a curve "
}, {
  title: 'Why is option premium higher than Intrinsic value before expiry?',
  subtitle: 'Stock price is the sole factor determining intrinsic value. Options, however, are determined by other factors.  Extrinsic value considers other factors that affect an options price, including when it will expire and the volatility of the stock.'
}, {
  title: 'Effect of volatility on long call option premium',
  subtitle: 'Volatility and option premium are positively correlated. In other words, as volatility increases, option premiums increase. When volatility decreases, option premiums decrease. '
}, {
  title: 'Effect of time to expiry on long call option premium',
  subtitle: 'Time left to expire and option premiums are negatively correlated. Thus, as time moves towards maturity, option premiums decrease. As the expiration date approaches, option premiums decrease. '
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const BoxExample = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  minWidth: 300
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const references = [{
  title: 'Equity Derivatives - Corporate and Institutional Applications',
  href: 'https://www.amazon.ca/Equity-Derivatives-Corporate-Institutional-Applications/dp/0230391060/ref=sr_1_3?crid=1RK806JY72D42&keywords=Neil+C+Schofield&qid=1658171436&sprefix=neil+c+schofield+%2Caps%2C100&sr=8-3'
}, {
  title: 'Michael C. Thomsett - Getting Started in Options-Wiley',
  href: 'https://www.amazon.ca/Getting-Started-Options-Michael-Thomsett/dp/1118399307/ref=cm_cr_arp_d_product_top?ie=UTF8'
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Container = makeShortcode("Container");
const ImageCard = makeShortcode("ImageCard");
const Content = makeShortcode("Content");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Link = makeShortcode("Link");
const References = makeShortcode("References");
const ReactPlayer = makeShortcode("ReactPlayer");
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  data,
Text9,
Text14,
items1,
ggbParameters,
BoxExample,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/GENERAL/delta_slide.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>
    <h1>{`GREEKS FOR LONG AND SHORT CALL OPTIONS`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Revision of basic topics."} mdxType="Content" />
    </Container>
    <br />
    <br />
    <h1>{`INTERACTIVE`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/call_greeks_seller.json" ggbParameters={ggbParameters} appId="appId2TestShort" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 


    <br />
    <br />
    <h1>{`Related Topics`}</h1>
    <br />
    <br />
    <h1>{`1. PRIcing variables and Option value`}</h1>
    <Container mdxType="Container">
      <Link href="http://options.21ifm.com/black_scholes" target="_blank" mdxType="Link">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/GREEKS/bs2_link.gif
" mdxType="ImageCard" />
      </Link>
    </Container>
    <h1>{`2.Theta`}</h1>
    <Container mdxType="Container">
      <Link href="http://options.21ifm.com/theta_3d" target="_blank" mdxType="Link">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/theta_3d.gif
" mdxType="ImageCard" />
      </Link>
    </Container>
    <h1>{`3.Delta`}</h1>
    <Container mdxType="Container">
      <Link href="http://options.21ifm.com/delta_3d" target="_blank" mdxType="Link">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/delta_indepth_trailer.gif
" mdxType="ImageCard" />
      </Link>
    </Container>

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;