
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { useState, useEffect } from "react";
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import SimpleAccordion from "../../components/SimpleAccordian";
import IfPaidUser from "../../components/IfPaidUser";
import useFetchData from "../../components/UseFetchData";
import ReactPlayer from 'react-player'
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import ReactPlayerComp from "../../components/ReactPlayerComp";
import SimpleDialog from "../../components/SimpleDialog";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import BlueLink from "../../components/Layouts/BlueLink";
import "../../components/style.css";
import LandmarkButton from '../../components/LandmarkButton';
import MyAudioPlayer from '../../components/Layouts/AudioPlayer';
import MyDraggableComponent from "../../components/DraggableComponent";
import GRCard from "../../components/GRCard";
import { Carousel } from 'react-responsive-carousel';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#1e1e1e",
      paper: "#1e1e1e"
    }
  }
});
export let blogs_chain = [];
export let mathTopicsJSON = [];
export const levels = [{
  title: "Beginner",
  description: "Complete novice with limited or no knowledge of options trading."
}, {
  title: "Intermediate",
  description: "Some experience with options trading but looking to deepen their understanding."
}, {
  title: "Advanced",
  description: "Experienced options trader seeking to further refine their skills and knowledge."
}];

function LessonPlans() {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [blogsChain, setBlogsChain] = useState([]);
  useEffect(() => {
    const fetchBlogs = async () => {
      const mathTopicsJSON = require("../index_levels.js").default();

      const newBlogsChain = _.chain(mathTopicsJSON).groupBy("category").map((value, key) => ({
        category: key,
        blogs: value
      })).value();

      setBlogsChain(newBlogsChain);
      setIsLoading(false);
    };

    fetchBlogs();
  }, []);

  const handleLevelClick = index => {
    setSelectedLevel(index);
  };

  return <ThemeProvider theme={darkTheme} mdxType="ThemeProvider">
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" mdxType="Box">
        <div>
          <div style={{
          maxWidth: '80%',
          margin: "2rem auto"
        }}>
            {levels.map((level, index) => <Card key={index} sx={{
            maxWidth: 345,
            display: "inline-block",
            margin: "1rem "
          }} mdxType="Card">
                <CardContent mdxType="CardContent">
                  <Typography variant="h5" mdxType="Typography">{level.title}</Typography>
                  <Typography variant="body2" color="text.secondary" mdxType="Typography">
                    {level.description}
                  </Typography>
                </CardContent>
                <CardActions mdxType="CardActions">
                  <Button onClick={() => handleLevelClick(index)} mdxType="Button">Select</Button>
                </CardActions>
              </Card>)}
          </div>
          <div>
            {selectedLevel !== null && <Card key={selectedLevel} sx={{
            maxWidth: '80%',
            margin: "2rem auto"
          }} mdxType="Card">
                <CardContent mdxType="CardContent">
                  <Typography variant="h4" mdxType="Typography">Lesson Plan: {levels[selectedLevel].title}</Typography>
                  <br />
                  {selectedLevel == 0 && <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/beginners_selection.png" mdxType="ImageCard" />}
                   {selectedLevel == 1 && <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/intermediate_selection.png" mdxType="ImageCard" />}                  
                  <br />
                  <CardsForBlogsNoNike key={selectedLevel} in_json={blogsChain[selectedLevel].blogs} title="" description="Long description" image="" mdxType="CardsForBlogsNoNike" />
                </CardContent>
              </Card>}
          </div>
        </div>
      </Box>
    </ThemeProvider>;
}
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Box = makeShortcode("Box");
const ImageCard = makeShortcode("ImageCard");
const CardsForBlogsNoNike = makeShortcode("CardsForBlogsNoNike");
const layoutProps = {
  darkTheme,
blogs_chain,
levels
};
const MDXLayout = LessonPlans
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;