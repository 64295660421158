
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const data = [{
  title: "1.Call price simulation",
  id: 1,
  script: 'Execute({"RunClickScript(scenarioB)"})',
  text: "How does Call value change with change in underlying price?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}, {
  title: "2.Call price derivation",
  id: 2,
  script: 'Execute({"RunClickScript(formulasB)"})',
  text: "Derive Call price from Put Call parity ",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}, {
  title: "3.Intrinsic value",
  id: 3,
  script: 'Execute({"RunClickScript(intextB)"})',
  text: "What factors influence Options Intrinsuic value?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}, {
  title: "4.Moneyness of Option",
  id: 4,
  script: 'Execute({"RunClickScript(itmB)"})',
  text: "When is Call option ITM, ATM and OTM",
  image: "",
  video: "https://www.youtube.com/embed/88eG8-3qpds"
}, {
  title: "5.Strike price, Asignment and exercise",
  id: 5,
  script: 'Execute({"RunClickScript(excB)"})',
  text: "Rights and Obligations of Call options seller and buyer",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}];
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const ReactPlayer = makeShortcode("ReactPlayer");
const layoutProps = {
  data,
Text9,
Text14,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`QQQ Case study`}</h1>
    <br />
    <br />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/casestudy_qqq/story.png
" mdxType="ImageCard" />
    <br />
    <br />
    <h1>{`INTERACTIVE`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_put/puts_price_simulation_flexi_qqq.json" ggbParameters={ggbParameters} appId="sell_put" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;