
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from "react-player";
import LessonPlans from "!babel-loader!@mdx-js/loader!./LessonPlans.mdx";
import Button from "@material-ui/core/Button";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "600",
  width: "800",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: "yellow"
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const RedditButton = ({
  url,
  text
}) => {
  const handleRedditButtonClick = () => {
    window.open(url, "_blank");
  };

  return <Button variant="contained" color="primary" onClick={handleRedditButtonClick} style={{
    display: "block",
    margin: "0 auto"
  }} mdxType="Button">
      {text}
    </Button>;
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const AppHeader = makeShortcode("AppHeader");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
DefaultBox,
Text4,
componetArray,
indexArray,
RedditButton
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <div style={{
      padding: 20,
      backgroundColor: "#1e1e1e",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }}>
  <YouTube videoId="eRXuPCKBcB4" opts={opts} mdxType="YouTube" />
    </div>
    <br />
    <br />
    <AppHeader mdxType="AppHeader" />
    <br />
    <br />
    <br />
    <br />
    <h1>{`Short Strangle interactive`}</h1>
    <p>{`{" "}`}</p>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/STRADDLE_CASE_3.json" ggbParameters={ggbParameters} appId="id2" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    <br />
    <br />
    <br />
    <br />


    <RedditButton url="https://www.reddit.com/r/options/comments/124wb3v/spx_12_delta_srangle_day_in_the_life_example/" text="Reddit post" mdxType="RedditButton" />
    <h1>{`Other topics from options book`}</h1>
    <LessonPlans mdxType="LessonPlans" />
    <RedditButton url="https://buy.stripe.com/cN203Ac410XZcYE3cj" text="BUY($299)" mdxType="RedditButton" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;