
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import GridList from "../../components/GridList";
import ButtonMailto from "../../components/ButtonMailto.js";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Button from '@mui/material/Button';
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import Link from '@mui/material/Link';
import VideoSection from "../../components/VideoSection";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: "100%",
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <>
    <div style={{
    padding: 20,
    color: 'gray',
    display: "flex",
    margin: "auto",
    width: "30%"
  }}>
      Each step includes interactive explainers and videos.
  </div>
  <div style={{
    padding: 20,
    backgroundColor: 'tomato',
    display: "flex",
    margin: "auto",
    width: "30%"
  }}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/IronCondorStep1.mp4' mdxType="ReactPlayer" />
  </div>
  </>;
export const Video0 = () => <>
  <div style={{
    padding: 20,
    backgroundColor: 'tomato'
  }}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_condor/video0_ic.mp4' mdxType="ReactPlayer" />
  </div>
  </>;
export const Video1 = () => <>
  <div style={{
    padding: 20,
    backgroundColor: 'tomato'
  }}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_condor/video1_ic.mp4' mdxType="ReactPlayer" />
  </div>
  </>;
export const Video2 = () => <>
  <div style={{
    padding: 20,
    backgroundColor: 'tomato'
  }}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_condor/video2_ic_1.mp4' mdxType="ReactPlayer" />
  </div>
  </>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'What is a Ratio Spread Startegy',
  subtitle: 'Ratio spread is a defined risk strategy that benefits from neutral to slightly bullish price movement.The trade will be profitable if the stock remains within a defined range , volatility drops and time passes'
}, {
  title: 'Which option strikes and options types are traded?',
  subtitle: 'This example shows 1 long call with lower strike and 2 short calls with higher strike'
}, {
  title: 'Is it a credit strategy or debit strategy?',
  subtitle: 'This depends on the distance between strikes, the time left to maturity, and the volatility when the trade is setup. The explainer below provides several scenarios to analyze these situations '
}, {
  title: 'What happens if price remains side ways and time decays?',
  subtitle: 'The most profit is made when a stock settles near the higher strike where calls are sold at maturity. '
}, {
  title: 'What is the Breakeven?',
  subtitle: 'If a debit ratio spread strategy is setup, there would be lower and higher breakevents that depend on the collected premium and max profit values. detailed explanation is provided in the explainer below  '
}];
export const references = [{
  title: 'Stock repair. Scott Nations. Options Spreads',
  href: "https://www.amazon.ca/Complete-Option-Spreads-Combinations-Website/dp/1118805453"
}, {
  title: '1X2 ratio spread. Fidelity',
  href: "https://www.fidelity.com/learning-center/investment-products/options/options-strategy-guide/short-calendar-spread-puts"
}, {
  title: "ASX: stock recovery",
  href: "https://www2.asx.com.au/investors/learn-about-our-investment-solutions/asx-options-knowledge-hub/investment-strategies/stock-repair#:~:text=The%20stock%20repair%20strategy%20is,are%20sold%20as%20are%20bought."
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const FlipCard = makeShortcode("FlipCard");
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
Video0,
Video1,
Video2,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    {
      /* 
      # Credit Condor Explainer
       <GeogebraDraw 
         jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/credit_condor_2022_2.json"
         ggbParameters={ggbParameters}
         appId="id2"
         email="pavanmirla@gmail.com"
         data= {[
           { component: <Intro/>, button: "text11" },
           {component: <Text4/>, button:  "text3"}
         ]}
       />
      
      # Credit Condor Greeks
      
       <GeogebraDraw 
         jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/volatality_short_condor.json"
         ggbParameters={ggbParameters}
         appId="id1"
         email="pavanmirla@gmail.com"
         data= {[
           { component: <Intro/>, button: "text11" },
           {component: <Text4/>, button:  "text3"}
         ]}
       /> */
    }
    <h1>{`STOCK REPAIR WITH RATIO SPREAD`}</h1>
    <br />
    <br />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bullish_call_spread/ratio_spread_neutral/ratio_intro_2.png" mdxType="ImageCard" />
    <br />
    <br />
    <h1>{`STOCK REPAIR WITH RATIO SPREAD`}</h1>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/ratio_spread_neutral/stock_repair_2.png" mdxType="ImageCard" />
    <h1>{`SETUP`}</h1>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bullish_call_spread/ratio_spread_neutral/ratio_spread_repair_story.png" mdxType="ImageCard" />

    <Container mdxType="Container">
  <Content items={items1} title={"Introduction"} mdxType="Content" />
    </Container>
    <h1>{`Interactive`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bullish_call_spread/ratio_spread_neutral/spreads_template_ratio_calls.json" ggbParameters={ggbParameters} appId="id3" email="pavanmirla@gmail.com" data={[{
      component: <Video0 mdxType="Video0" />,
      button: "video0"
    }, {
      component: <Video1 mdxType="Video1" />,
      button: "video1"
    }, {
      component: <Video2 mdxType="Video2" />,
      button: "video2"
    }]} mdxType="GeogebraDraw" />
    {
      /* https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/condor_scripted.json */
    }
    <br />
    <br />
    <br />
    <h1>{`QUESTION 1`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/RATIOS/q1_f.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/RATIOS/q1_b.png" mdxType="FlipCard" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`QUESTION 2`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/RATIOS/q2_fixed_f.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/RATIOS/q2_b.png" mdxType="FlipCard" />
    <br />
    <br />

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;