import React, {
  useEffect,
  useState,
  useContext,
  lazy,
  Component,
  Suspense,
} from "react";
import SingleCard from "./components/singleCard";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import FullScreenDialog from "./components/DialogComponent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import AppHeader from "./components/AppHeader";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import NikeDesign from "./components/NikeDesign.js";
import { useTheme } from "@mui/material/styles";
import MessageModal from "./components/MessageModal";
import firebase from "firebase";

import { firebaseAuth } from "./provider/AuthProvider";

export default function CardsForBlogs({ in_json, title, description, image }) {
  let componentsAndIds = [];
  in_json.forEach((blog) => {
    componentsAndIds.push(blog);
  });

  const { inputs, setInputs, errors, token, paymentFlag } =
    useContext(firebaseAuth);

  return (
    <div>
      <LoadCards
        in_json={componentsAndIds}
        title={title}
        description={description}
        image={image}
        token={token}
        paymentFlag={paymentFlag}
      />
    </div>
  );
}

/*

*/
function LoadCards({ in_json, title, description, image, token, paymentFlag }) {
  const drawerWidth = 240;
  const theme = useTheme();

  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: "flex",
      backgroundColor: "#1E1e1E",
    },

    eachChapter: {
      // flexGrow: 1,
      backgroundColor: "#1E1E1E",
      padding: theme.spacing(2),
      // margin: "auto",
      direction: "column",
      alignItems: "center",
      justify: "center",
      minHeight: "50vh",
    },
    appbar: {
      // backgroundColor: "black",
      color: "lightgray",
    },

    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 500,
    },
    control: {
      padding: theme.spacing(2),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      fontWeight: 200,
    },
    lightFont: {
      fontWeight: 200,
    },
    toolbar: theme.mixins.toolbar,
    appMarginLeft: {
      // width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    content: {
      flexGrow: 1,
      backgroundColor: "#1E1E1E",
      padding: theme.spacing(3),
      marginTop: 60,
    },
  }));

  const handleDialogOpen = (currentTarget, title) => {
    SetDialogTitle(title);
    const clickedBoxId = currentTarget;

    // firebase log
    if (authUser) {
      const userRef = firebase
        .firestore()
        .collection("users")
        .doc(authUser.uid);

      userRef.update({
        activity: firebase.firestore.FieldValue.arrayUnion({
          target: currentTarget,
          title: title,
          timeStamp: new Date(),
        }),
      });
    }
    const matchedPageObject = pages.filter((d) => d.id === clickedBoxId);
    let pageComponent = <DefaultComponent />;
    if (matchedPageObject.length !== 0) {
      pageComponent = matchedPageObject[0].Component;
    }
    SetDialogChild(pageComponent); // This is what gets showed up in Dialog. Look for math/index.js for id's and components
    SetIsOpen(true);
  };

  const handleDialogClose = () => { 
    SetIsOpen(false);
  };


  const handleAlertDialogOpen = () => {
    SetIsOpenAlert(true);
  };


  const handleDrawerClick = () => {
    // SetIsDrawerOpen(false);
    SetIsDrawerOpen((prevCheck) => !prevCheck);
  };

  let history = useHistory();
  const classes = useStyles();
  const [isOpen, SetIsOpen] = useState(false);
  const [isOpenAlert, SetIsOpenAlert] = useState(false);

  // const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [isDrawerOpen, SetIsDrawerOpen] = useState(true);
  const [dialogTitle, SetDialogTitle] = useState("");
  const [DialogChildsOpen, SetDialogChild] = useState(
    <>
      <DefaultComponent />
    </>
  );
  let pages = in_json;

  let blogCards = [];
  let blogArray = [];

  in_json.forEach((blog) => {
    if (blog.active === 1) {
      blogArray.push(
        <Grid key={blog.name} item>
          <ButtonBase
            key={blog.name}
            id={blog.id}
            className={classes.cardButton}
            onClick={(e) => {
              if (paymentFlag) {
                handleDialogOpen(e.currentTarget.id, blog.name);
              } else {
                const result = confirm('Payment required. Do you want to go to the payment page?');
                if (result) {
                // user clicked OK, navigate to payment page
                window.location.href = '/buyclick';
                } else {
                // user clicked Cancel, do nothing
                }
                // history.push("/")
              }
            }}
          >
            <SingleCard
              key={blog.id}
              title={blog.name}
              image={blog.image}
              video={blog.video}
              description={blog.description}
              level={blog.level}
              token={token}
              paymentFlag={paymentFlag}
            />
          </ButtonBase>
        </Grid>
      );
    }
  });

  const element = (
    <>
      <Grid
        container
        spacing={20}
        direction="column"
        alignItems="left"
        justify="left"
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            {blogArray}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
  blogCards.push(element);

  return (
    <div className={classes.root}>
      {/* <ClippedDrawer isDrawerOpen={isDrawerOpen} categoryList={categoryList}/> */}
      {/* <AppHeader handleDrawerToggle={handleDrawerClick}/> */}

      <main className={classes.content}>
        <NikeDesign
          blogCards={[blogCards]}
          title={title}
          description={description}
          image={image}
        />
        {/* {blogCards} */}

        <FullScreenDialog
          isOpen={isOpen}
          handleClose={handleDialogClose}
          title={dialogTitle}
          style={{ position: "fixed", backgroundColor: "#1E1E1E" }}
        >
          {DialogChildsOpen}
        </FullScreenDialog>
      </main>
    </div>
  );
}

function DefaultComponent() {
  const text = "Hello World . I am inside math.js / App3";

  return (
    <div className="App">
      <p> {text} </p>
    </div>
  );
}

//   This is a functional React component named "CardsForBlogs". It receives 5 props (in_json, title, description, image) and maps through the in_json prop to create an array of components and ids. Then, it makes use of the useContext hook to extract values (inputs, setInputs, errors, token, paymentFlag) from a context created by the firebaseAuth module.

// It then returns a single div that includes a LoadCards component and passes the extracted data along with the props that were passed to the CardsForBlogs component.

// The LoadCards component uses these props to display blog cards. It first sets up the styles for the components using the makeStyles hook from the Material-UI library. It also sets up a state for the authentication user using useState and listens to changes using the useEffect hook.

// It contains functions for opening and closing a dialog, toggling the drawer, and handling a button click. When a button is clicked, the handleDialogOpen function is called which sets the dialog title, adds user activity data to Firebase, and sets the dialog child component. The handleDialogClose function closes the dialog.

// The LoadCards component also has a let history = useHistory() line, which means it is using the useHistory hook from the react-router-dom library to access the history object. This is likely used for navigation purposes.

// It then maps through the in_json prop, filters it based on the active key, and creates blog cards to be displayed.
