import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './styles.css'


import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import CheckPayment from "./CheckPayments"



const Task = ({ task, index }) => {
    return (
      <Draggable draggableId={task.id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="task"
          >
            {task.content}
          </div>
        )}
      </Draggable>
    );
  };

  

const Column = ({ column }) => {
    return (
      <div className="column">
        <h2>{column.title}</h2>
        <Droppable droppableId={column.id}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className="task-list"
              {...provided.droppableProps}
            >
              {column.tasks.map((task, index) => (
                <Task key={task.id} task={task} index={index} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  

const Board = () => {

  const [columns, setColumns] = useState({
    'column-1': {
      id: 'column-1',
      title: 'To Do',
      tasks: [
        { id: 'task-1', content: 'Task 1' },
        { id: 'task-2', content: 'Task 2' },
        { id: 'task-3', content: 'Task 3' },
      ],
    },
    'column-2': {
      id: 'column-2',
      title: 'In Progress',
      tasks: [
        { id: 'task-4', content: 'Task 4' },
        { id: 'task-5', content: 'Task 5' },
      ],
    },
    'column-3': {
      id: 'column-3',
      title: 'Done',
      tasks: [
        { id: 'task-6', content: 'Task 6' },
      ],
    },
  });

  const [userId, setUserId] = useState("");


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);



  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, [firebase]);

//   useEffect(() => {
//     if (!userId) return;
//     const unsubscribe = firebase
//       .firestore()
//       .collection("users")
//       .doc(userId)
//       .onSnapshot(doc => {
//         if (doc.exists) {
//         if(typeof doc.data().columns !== "undefined")
//         {
//             alert("COLUMNS EXISTS");
//             setColumns(doc.data().columns);
//         } else {
//             alert("COLUMNS doesn't EXISTS");
//             saveData()
//         } 
//         }
//       });
//     return () => unsubscribe();
//   }, [userId, firebase]);


useEffect(() => {
    if (!userId) return;
    const unsubscribe = firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .onSnapshot(doc => {
        if (doc.exists) {
          const columns = doc.get("columns");
          if (columns) {
            alert("COLUMNS EXISTS");
            setColumns(columns);
          } else {
            alert("COLUMNS doesn't EXISTS");
            saveData();
          }
        }
      });
    return () => unsubscribe();
  }, [userId, firebase]);

  const saveData = async () => {
    if (!userId) {
      return alert("You need to be logged in to save data");
    }
    try {
        const paymentsRef = firebase
            .firestore()
            .collection("users")
            .doc(userId)
            .collection("payments");
        const paymentsDoc = await paymentsRef.get();
        if (paymentsDoc.empty) {
            return alert("You need to make a payment before saving data");
        }
        await firebase
            .firestore()
            .collection("users")
            .doc(userId)
            .set({ columns: columns }, { merge: true });
    } catch (error) {
        alert(error)
        console.log(error);
    }
};



  const loginWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
        await firebase.auth().signInWithPopup(provider);
    } catch (error) {
        console.log(error);
    }
  };



  const onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      const newTasks = [...columns[source.droppableId].tasks];
      const task = newTasks.splice(source.index, 1)[0];
      newTasks.splice(destination.index, 0, task);
      setColumns(prevColumns => {
        return {
          ...prevColumns,
          [source.droppableId]: {
            ...prevColumns[source.droppableId],
            tasks: newTasks,
          },
        };
      });
    } else {
      const sourceTasks = [...columns[source.droppableId].tasks];
      const task = sourceTasks.splice(source.index, 1)[0];
      const destinationTasks = [...columns[destination.droppableId].tasks];
      destinationTasks.splice(destination.index, 0, task);
      setColumns(prevColumns => {
        return {
          ...prevColumns,
          [source.droppableId]: {
            ...prevColumns[source.droppableId],
            tasks: sourceTasks,
          },
          [destination.droppableId]: {
            ...prevColumns[destination.droppableId],
            tasks: destinationTasks,
          },
        };
      });
    }
  };

  return (
    <div>
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="board-container">


           {Object.values(columns).map(column => (
          <Column key={column.id} column={column} />
        ))}
       
    

      </div>
    </DragDropContext>
    <button onClick={saveData}>Save Data</button>
    <button onClick={loginWithGoogle}>Login with Google</button>
    </div>
  );
}
export default Board