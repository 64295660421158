import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqGroupItem = (props) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography color="lightgray" fontWeight={700} variant={'h5'}>
          {/* {props.title} */}
        </Typography>
      </Box>
      <Box>
          <Box
            component={Accordion}
            key={"one"}
            padding={1}
            // marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            backgroundColor: "#373737",
            color: "#90caf9"
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography fontWeight={600}>{props.title}</Typography>
            </Box>
            <AccordionDetails>
              {/* <Typography color="lightgray">{item.subtitle}</Typography>
               */}
               {props.children}
            </AccordionDetails>
          </Box>
      </Box>
    </Box>
  );
};

// FaqGroupItem.propTypes = {
//   title: PropTypes.string.isRequired,
//   items: PropTypes.array.isRequired,
// };

const Content = (props) => {
  return (
    <Box>
      <Box marginBottom={6} >
        <FaqGroupItem
          title={props.title}
          children={props.children}
        />
      </Box>
   
    
    </Box>
  );
};

export default Content;
