
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import VideoSection from "../../components/VideoSection";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1e1e1e",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const items3 = [{
  title: ' Why are there two charts?',
  subtitle: 'Because the strategy to profit from mispricing is to sell the expensive portfolio and buy the cheaper one, I thought showing them separately would be less cluttered. But this is not a standard practice.'
}, {
  title: 'Where is this useful?',
  subtitle: 'This is a must-know formula since synthetic portfolios can be replicated with either calls or puts. '
}, {
  title: 'Exercise',
  subtitle: 'Compare the payoff of a covered call and a put write. Are they similar? The parity of the put call plays a part. '
}];
export const references = [{
  title: 'Payoff of each investment as stock price changes',
  href: 'https://www.optionseducation.org/advancedconcepts/put-call-parity'
}, {
  title: 'Reverse Conversion Options Play Explained',
  href: 'https://www.newtraderu.com/2020/05/28/reverse-conversion-options-play-explained/'
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
Box11,
Box22,
items3,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    <h1>{`Put call parity formula`}</h1>
    <br />
 <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/conversions/conversions_derivation_2.json" ggbParameters={ggbParameters} appId="form1" email="pavanmirla@gmail.com" data={[{
      component: <Box11 mdxType="Box11" />,
      button: "textn1"
    }, {
      component: <Box22 mdxType="Box22" />,
      button: "textn2"
    }]} mdxType="GeogebraDraw" />
    {
      /* jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/reverse_conversion_6.json" */
    }

    <Container mdxType="Container">
  {
        /* <Content items={items3} title={"Explainer Highlights"}/> */
      }
    </Container>
    <br />

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;