import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { firebaseAuth } from "./provider/AuthProvider";

import Heading from "./components/Heading";

import SignUp from "./components/SignUp";
import Payment from "./components/Payment";
import VideoSection from "./components/VideoSection";

import SignInMagicLink from "./components/SignInMagicLink";
import MagicLinkWait from "./components/MagicLinkWait";
import SignIn from "./components/SignIn";
import BuyClick from "./components/BuyClick";
import NotFound from "./components/NotFound";


import SignInEmail from "./components/SignInEmail";
import SignInPlusPurchase from "./components/SignInPlusPurchase";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import ForgotPassword from "./components/ForgotPassword";
import GeogebraDraw from "./components/GeogebraDraw";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Demo from "./components/SimpleVideoCard";
import ImageCard from "./components/ImageCard";
import OverviewFlow from "./components/OverviewFlow";
import GridList from "./components/GridList";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MessageModal from "./components/MessageModal";
import ButtonMailto from "./components/ButtonMailto.js";
import WhoAmI from "./components/WhoAmI.js";
import Sample from "./components/Pdf/Sample.jsx";
// import InLine from "./components/InLine";
// const About = lazy(() => importMDX('./pages/About.mdx'));
import { Portal } from "react-portal";
import YouTube from "react-youtube";

import CardsForBlogs from "./CardsForBlogs";
import AppHeader from "./components/AppHeader";
import SignUpLicense from "./components/SignUpLicense";
import AreaMap1 from "./components/AreaMap1";
import AreaMap2 from "./components/AreaMap2";
import Login from "./components/Login.tsx";
import { createCheckoutSession } from "./stripe/createCheckoutSession";
import usePremiumStatus from "./stripe/usePremiumStatus";
import isPaidUserTest from "./stripe/isPaidUserTest";
import useLocalStorage from "./firebase/uselocalstorage";
import { Redirect, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import { useHistory } from "react-router-dom";

import OpenVideo from "./components/OpenVideo";
import ReactPlayer from "react-player";
import SimpleDialog from "./components/SimpleDialog";
import LoginDialog from "./components/LoginDialog";

import OpenPopup from "./components/OpenPopup";

import MyCarousel from "./components/MyCarousel";
import BlinkingButton from './components/BlinkingButton';
import Container from "./components/Layouts/Container";
import Quiz from "./components/Quiz.js";
import QUIZ_HOME from '!babel-loader!@mdx-js/loader!./pages_posts/posts/QUIZ_HOME.mdx'
import MarketData from '!babel-loader!@mdx-js/loader!./pages_posts/posts/MarketData.mdx'
import LessonPlans from '!babel-loader!@mdx-js/loader!./pages_posts/posts/LessonPlans.mdx'
import SignInPay from '!babel-loader!@mdx-js/loader!./pages_posts/posts/SignInPay.mdx'
import TOC from '!babel-loader!@mdx-js/loader!./pages_posts/posts/TOC.mdx'
import STRANGLE_ROLLING from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STRANGLE_ROLLING.mdx'

import Seagull_Setup from '!babel-loader!@mdx-js/loader!./pages_posts/posts/Seagull_Setup.mdx'
import OptimizationBox from '!babel-loader!@mdx-js/loader!./pages_posts/posts/OptimizationBox.mdx'



const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
    },
  },
});

const componentsMap = {
  h1: Heading.h3, // mapping h3 to h1 so we're having a smaller font-size
  h2: Heading.h4,
};

function SubHeading(props) {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            // mt: 250,
            bgcolor: "#1E1E1E",
            width: 100,
            p: 1,
            // m: 1,
            borderRadius: 1,
            textAlign: "center",
          }}
        >
          <Typography variant="h3" gutterBottom component="div" color="#90caf9">
            {props.title}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

let blogs_chain;
export default function MathApp() {
  const [isDrawerOpen, SetIsDrawerOpen] = useState(false);
  const [userProfile, SetuserProfile] = useState("notbeginner");
  const {
    token,
    handleSignout,
    inputs,
    currentUser,
    setCurrentUser,
    setPaymentFlag,
    paymentFlag,
  } = useContext(firebaseAuth);
  const [showLessonPlans, setShowLessonPlans] = useState(false);

  const userIsPremium = usePremiumStatus(currentUser);
 
  const handleClick = () => {
    setShowLessonPlans(!showLessonPlans);
  };

  const [currentUsertest, setCurrentUserTest] = useState(null);
  let history = useHistory();


  const ggbParameters2 = {
    // material_id: "",
    // width: "100%",
    // height: "100%",
    borderColor: "#1E1e1E",
    enableShiftDragZoom: false,
    showToolBar: false,
  };







  useEffect(() => {
    if (!window.localStorage.getItem("previousLocation")) {
      window.localStorage.setItem("previousLocation", '/');
    }
  }, []);


  useEffect(() => {
    firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setPaymentFlag(window.localStorage.getItem("paymentFlag"));
      } else {
        setCurrentUser(null);
      }
    });
    // localStorage.setItem("clicked_buy", false)
  }, []);

  let mathTopicsJSON = [];

  try {
    mathTopicsJSON = require(`./pages_posts`).default();
    blogs_chain = _.chain(mathTopicsJSON)
      // Group the elements of Array based on `color` property
      .groupBy("category")
      // `key` is group's name (color), `value` is the array of objects
      .map((value, key) => ({ category: key, blogs: value }))
      .value();
    console.log(blogs_chain);
  } catch (err) {}

  const handleDrawerClick = () => {
    // SetIsDrawerOpen(false);
    SetIsDrawerOpen((prevCheck) => !prevCheck);
  };

  const handleIsbeginnerClick = (profile) => {
    SetuserProfile(profile);
  };

  const clicked_on = (shape) => {
    alert("You clicked on the " + shape);
  };

  const ggbParameters = {
    // material_id: "",
    // width: "100%",
    // height: "100%",
    borderColor: "#1E1e1E",
    enableShiftDragZoom: false,
    showToolBar: false,
  };

  const MyDynamicButton = () => {
    const [count, setCount] = useState(0);
    const [show, setShow] = useState(false);
    return (
      <Box textAlign="center">
        <Button
          color={"primary"}
          variant={"contained"}
          size={"large"}
          style={{ margin: "0 auto", display: "flex" }}
          onClick={() => {
            if (show == false) setShow(true);
            if (show == true) setShow(false);
            setCount(count + 1);
          }}
        >
          Click to{" "}
          {show == true ? "Close 3D App" : " view Payoff surface in 3D "}
        </Button>
        {show == true && (
          <GeogebraDraw
            jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/options_intro/journey_treasure.json"
            ggbParameters={ggbParameters}
            appId="appId2"
            email="pavanmirla@gmail.com"
            data={[]}
          />
        )}
      </Box>
    );
  };

  const MyLink = () => {
    const handleClick = () => {
      const newWindow = window.open(
        "http://localhost:3000/landscape",
        "_blank"
      );
      if (newWindow) {
        newWindow.focus();
      }
    };

    return (
      <a href="#" onClick={handleClick}>
        Open Example in new window
      </a>
    );
  };

  const Player = () => {
    const ref = React.createRef();
    return (
      <div>
        <div
          style={{
            position: "fixed",
            top: "55%",
            left: "1%",
            width: "500px",
            height: 500,
          }}
        >
          {/* <h1>Buy</h1> */}
          <Link href="/signin" color="primary">
            {"Google signin"}
          </Link>
          <div>
            <img
              style={{ maxWidth: "100%", maxHeight: "100%", display: "block" }}
              src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/buy_pic.png"
            ></img>
          </div>
        </div>
      </div>
    );
  };

  const GeoOpen = () => {
    const ref = React.createRef();
    return (
      <div>
        <GeogebraDraw
          jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/call_intro/risk_graph_call_1.json"
          ggbParameters={ggbParameters}
          appId="parity_grap_call"
          email="pavanmirla@gmail.com"
          data={[]}
        />
      </div>
    );
  };

  const Video2 = () => (
    <>
      <div
        style={{
          padding: 20,
          backgroundColor: "tomato",
          width: "600px",
          height: "400px",
        }}
      >
        <ReactPlayer
          controls="true"
          width="100%"
          height="100%"
          url="https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_condor/video2_ic_1.mp4"
        />
      </div>
    </>
  );





  const ProtectedRoute = ({ component: Component, defaultComponent: DefaultComponent, ...rest }) => {
    const [isPaidUser, setIsPaidUser] = useState(false);
  
    useEffect(() => {
      const checkPaymentStatus = async () => {
        const isPaid = await isPaidUserTest();
        setIsPaidUser(isPaid);
      };
  
      checkPaymentStatus();
    }, []);
  
    window.localStorage.setItem("previousLocation", window.location.pathname);
    const urlKey = window.location.pathname.split("/").pop();
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key'); 
    // Check if the payment confirmation alert has already been shown
    const paymentConfirmationShown = localStorage.getItem("paymentConfirmationShown");
    if (!paymentConfirmationShown && !isPaidUser) {
      // If it hasn't been shown and the user is not paid, show the alert
      const result = window.confirm("Payment required. Do you want to go to the payment page?");
      if (result) {
        // user clicked OK, navigate to payment page
        window.location.href = "/buyclick";
      } else {
        // user clicked Cancel, do nothing
      }
      // Set the paymentConfirmationShown flag in localStorage to prevent showing the alert again
      localStorage.setItem("paymentConfirmationShown", true);
    }
    return (
      <Route {...rest} render={(props) => (
        isPaidUser === true || key === 'access2023'
          ? <Component {...props} />
          : <DefaultComponent {...props} />
      )} />
    );
  };
  

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Switch>
          {mathTopicsJSON.map(({ id, Component }) => (
            // <Route key={id} path={"/" + id}>
            //   {<Component />}
            // </Route>
            <ProtectedRoute key={id} path={"/" + id} component={Component} defaultComponent={SignIn} />

          ))}
          <Route exact path="/">
            <AppHeader handleDrawerToggle={handleDrawerClick} />
            {blogs_chain.length > 0 && (
              <>
                {currentUser == null && !paymentFlag && (
                  <div
                    style={{
                      zIndex: 10000000000,
                      position: "fixed",
                      bottom: "5%",
                      left: "0%",
                      padding: "18px 36px",
                      width: "300px",
                      height: "50px",
                      // border: "3px solid #73AD21",
                      // backgroundColor: "#373737",
                      cursor: "pointer",
                      fontFamily: "Arial",
                      // border: "2px solid gray"
                    }}
                  >
                    <Button
                      style={{
                        // borderRadius: 35,
                        backgroundColor: "#f2c744",
                        padding: "18px 36px",
                        fontSize: "12px",
                        color: "black",
                      }}
                      onClick={(event) => {
                        localStorage.setItem("clicked_buy", true);
                        window.location.href = "/buyclick";
                      }}
                      variant="contained"
                      color="info"
                    >
                      {!paymentFlag && (
                          <>
                            <div style={{fontSize: "12px"}}>BUY</div>
                            <br/>
                            <div style={{fontSize: "12px", fontWeight: "bold"}}>($299)</div>
                          </>
                      )}
                      <br />
                    </Button>
                  </div>
                )}
        
                {currentUser != null && (
                  <>
                    {!paymentFlag ? (
                      <>
                        <div
                          style={{
                            zIndex: 10000000000,
                            position: "fixed",
                            top: "30%",
                            left: "40%",
                            padding: "18px 36px",
                            width: "300px",
                            height: "450px",
                            // border: "3px solid #73AD21",
                            backgroundColor: "#373737",
                            cursor: "pointer",
                            fontFamily: "Arial",
                            border: "2px solid gray",
                          }}
                          onClick={() => createCheckoutSession(currentUser.uid)}
                        >
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "gray" }}
                          >
                            Hello, ✋{currentUser.email}
                            <br />
                            <br />
                          </Typography>

                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "white" }}
                          >
                            $299 for five year access
                            <br />
                          </Typography>

                          <br />
                          <Button
                            style={{
                              // borderRadius: 35,
                              backgroundColor: "#f2c744",
                              padding: "18px 36px",
                              fontSize: "12px",
                              color: "black",
                              zIndex: 100000,
                            }}
                            onClick={() => () =>
                              createCheckoutSession(currentUser.uid)}
                            variant="contained"
                            color="info"
                          >
                            {"Make a one time payment "}
                          </Button>
                          <br />
                          <br />

                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "white" }}
                          >
                            Hit the button, wait 5-10 seconds for Stripe magic
                            to happen, complete payment then relogin f
                          </Typography>
                          <br />
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "gray" }}
                          >
                            Having trouble? Payment made already? Contact
                            office@21ifm.com for a quick fix
                          </Typography>
                          <br />

                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "white" }}
                          >
                            Can't click the button? Try this alternative option.
                            <br />
                          </Typography>
                          <a
                            href="https://buy.stripe.com/cN203Ac410XZcYE3cj"
                            target="_blank"
                            style={{ color: "#87CEEB" }}
                          >
                            Buy Now
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            zIndex: 10000000000,
                            position: "fixed",
                            display: "hide",
                            bottom: 0,
                            right: 0,
                            width: "200px",
                            height: "60px",
                            // border: "3px solid #73AD21",
                            backgroundColor: "#fecf02",
                            cursor: "pointer",
                          }}
                        >
                          {currentUser.email}
                          🍪 Premium customer!
                        </div>
                      </>
                    )}
                  </>
                )}

                <br />
           
          <ButtonMailto label="EMAIL" mailto="mailto:office@21ifm.com?subject=Note on SOFR chapter" top= {"91%"} left={"88%"}/>
        

                {!paymentFlag && (
                  <>
                    <CardsForBlogs
                      in_json={[]}
                      title="AN INTERACTIVE, SIMULATIONS BASED  E-LEARNING PLATFORM TO MASTER OPTIONS."
                      description="Long description"
                      image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/video_linkedin_3.mp4"
                    />

                    <br />

        
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonMailto
                        label="Email"
                        mailto="mailto:office@21ifm.com?subject=I would like to setup a demo!&body=Thanks!"
                      />
                    </Box>
                  </>
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                {/* <GeogebraDraw
                  jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/landscape/strategy_diag_8.json"
                  ggbParameters={ggbParameters}
                  appId="app_home"
                  email="pavanmirla@gmail.com"
                  data={[
                    { component: <Video2 />, button: "pic7" },
                    { component: <GeoOpen />, button: "pic6" },
                    { component: <MyLink />, button: "pic5" },
                  ]}
                />  */}

                <CardsForBlogs
                  in_json={[]}
                  title="WHY LEARN OPTIONS?"
                  description="Long description"
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/INTRO/UPCOMING/audience.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/merton_intro.jpg,
                  https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/fin_options.jpg"
                /> 


                <div>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="20vh"
                    flexDirection="column"
                  >
                    <Button variant="contained" color="primary" onClick={handleClick}>
                      Toggle Lesson Plans
                    </Button>
                    {showLessonPlans && <LessonPlans />}
                  </Box>
                </div>


                 <CardsForBlogs
                  in_json={blogs_chain[2].blogs}
                  title="CALL OPTIONS INTRINSIC VALUE, EXTRINSIC VALUE, MONEYNESS, EXERCISE, ASSIGNMENT AND PRICING "
                  description="Long description"
                  image="none"
                />
                <QUIZ_HOME/>

                 <CardsForBlogs
                  in_json={blogs_chain[16].blogs}
                  title="BUY AND SELL CALLS TO CREATE DEFINED RISK CALL SPREADS"
                  description="Long descption"
                  image="none"
                /> 

                <CardsForBlogs
                  in_json={blogs_chain[4].blogs}
                  title="MATHEMATICS OF CALL OPTION PRICING"
                  description="Long description"
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/sinclair_quote.jpg,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/myron_quote.jpg,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/warren_quote.jpg"
                />

                <CardsForBlogs
                  in_json={blogs_chain[5].blogs}
                  title="PUT OPTIONS INTRINSIC VALUE, EXTRINSIC VALUE, MONEYNESS, EXERCISE, ASSIGNMENT AND PRICING "
                  description="Long descption"
                  image="none"
                /> 

                 <CardsForBlogs
                  in_json={blogs_chain[9].blogs}
                  title="BUY AND SELL PUTS TO CREATE DEFINED RISK SPREADS"
                  description="Long descption"
                  image="none"
                /> 

                <CardsForBlogs
                  in_json={blogs_chain[6].blogs}
                  title="SYNTHETIC STOCK AND OPTIONS. PUT-CALL PARITY. ARBITRAGE"
                  description="Long descption"
                  image="none"
                />

                {userProfile == "notbeginner" && (
                  <>
                    <CardsForBlogs
                      in_json={blogs_chain[8].blogs}
                      title="HISTORICAL AND IMPLIED VOLATILITY "
                      description="Long description"
                      image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/arch/engle_2.png"
                    />

                    <CardsForBlogs
                      in_json={[]}
                      title="BUYING AND SELLING CALLS ALONGSIDE PUTS. VOLATILITY STRATEGIES "
                      description="Long description"
                      image="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/martha_stewart.png"
                    />

                   <CardsForBlogs
                      in_json={blogs_chain[12].blogs}
                      title="TIME SPREADS. DIFFERENT EXPIRY DATES AND DIFFERENT STRIKE PRICES"
                      description="Long descption"
                      image="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/GENERAL/spreads_comparison.png"
                    /> 

                    <CardsForBlogs
                      in_json={blogs_chain[13].blogs}
                      title="HEDGING AND INCOME GENERATION WHILE HOLDING UNDERLYING"
                      description="Long descption"
                      image="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/GENERAL/cuban_anim.gif"
                    /> 

                  </>

                )}

                {/* <CardsForBlogs in_json={blogs_chain[14].blogs} title="RISK MANAGEMENT" description = "Long description"
          image = "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/allianz_leeson.gif" /> */}

                <CardsForBlogs
                  in_json={blogs_chain[3].blogs}
                  title="ESSENTIAL MATHEMATICS AND STATISTICS"
                  description="Long description"
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/basics/galton_bonomial.gif"
                />

               <CardsForBlogs
                  in_json={blogs_chain[17].blogs}
                  title="EMPLOYEE STOCK OPTIONS AND TAXES"
                  description="Long descption"
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/esop/bb_fixed.png"
                /> 

                <CardsForBlogs
                  in_json={[]}
                  title="NEW CHAPTERS IN APR 2023"
                  description="Long descption"
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/13.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/QUIZ/openbb.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/INTRO/UPCOMING/50.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/INTRO/UPCOMING/51.png"
                />

                {/* <CardsForBlogs
                  in_json={blogs_chain[15].blogs}
                  title="WEEKLY MARKET NEWS, OPPORTUNITIES AND CONCEPT REVISIONS"
                  description="Long descption"
                  image="none"
                /> */}
            

                <MarketData/>
                <br/>
                <br/>
                <br/>
                <TOC/>


{/* {/* <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/FLOW/with_calls.png"/> */}
{/* <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/FLOW/101.png"/>
<ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/FLOW/102.png"/>
<ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/FLOW/103.png"/>
<ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/FLOW/104.png"/>  */}


{!paymentFlag && (
                  <>
                
                    <br />

                    <CardsForBlogs
                      in_json={[]}
                      title="INTERACTIVE OPTIONS BOOK REVIEWS"
                      description="Long description"
                      image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/IMF/REVIEWS/25.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/IMF/REVIEWS/26.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/IMF/REVIEWS/27.png"
                    />

                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonMailto
                        label="Email"
                        mailto="mailto:office@21ifm.com?subject=I would like to setup a demo!&body=Thanks!"
                      />
                    </Box>
                  </>
                )}


                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <ButtonMailto
                    label="Email"
                    mailto="mailto:office@21ifm.com?subject=I would like to setup a demo!&body=Thanks!"
                  />
                </Box>
              </>
            )}
          </Route>

          <Route path="/taxes">
            <h2>Taxes</h2>
          </Route>




          <Route path="/loans">
            <h2>Loans</h2>
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/register">
            <SignUp />
          </Route>
          <Route path="/PutCollarSpread">
            <LoginDialog>
               <Seagull_Setup />
            </LoginDialog>
          </Route>

          <Route path="/optimization">
            <LoginDialog>
               <OptimizationBox />
            </LoginDialog>
          </Route>


        



          <Route path="/signinLink">
            <SignInMagicLink />
          </Route>

          <Route path="/signinemail">
            <SignInEmail />
          </Route>

          <Route path="/payment">
            <Payment />
          </Route>

          <Route path="/wait">
            <MagicLinkWait />
          </Route>

          <Route path="/forgotPassword">
            <ForgotPassword />
          </Route>

          <Route path="/signin">
            <SignIn />
          </Route>

          <Route path="/buyclick">
            <BuyClick />
          </Route>

          <Route path="/strangle_rollup_open">
            <STRANGLE_ROLLING />
          </Route>



         <Route path="/purchase">
            <SignInPlusPurchase />
          </Route>

          <Route path="/__/auth/">
            <SignIn />
          </Route>

          <Route path='*' component={NotFound} />

        </Switch>
      </Router>
    </ThemeProvider>
  );
}

function Home() {
  return (
    <div>
      {/* <AppHeader handleDrawerToggle={handleDrawerClick}/> */}
      <main></main>
    </div>
  );
}
