
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import ButtonMailto from "../../components/ButtonMailto.js";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
import HighlightSpeak from "../../components/HighlightSpeak";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'What is Time spread? ',
  subtitle: "Time spreads involve options of same underlying stock, the same series (either both calls or both puts), but different expiration months"
}, {
  title: 'What is a Calendar spread?',
  subtitle: 'This spread involves the same underlying and the same strike with different expiration months. Example: Buy 1 June $100 call and sell 1 March $100 call. \
                In a short calendar, buy 1 March $100 call and sell 1 June $100 call '
}, {
  title: 'What is a Diagonal spread?',
  subtitle: 'Diagonal spread is a variant of Calendar spread with different strikes.Diagonal spreads involve same underlying, same strike but different expiration months.'
}, {
  title: 'Non linear Risk profile',
  subtitle: 'Since options with different expirations are used, risk profile/payoff is not linear'
}];
export const references = [{
  title: 'What is Time spread? ',
  href: "https://www.fidelity.com/learning-center/investment-products/options/options-strategy-guide/short-calendar-spread-puts"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
Text4,
indexArray,
items1,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <br />
    <br />

    <Container mdxType="Container">
  <Content items={items1} title={"Time spreads introduction"} mdxType="Content" />
    </Container>
    <br />
    <br />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/neutral_calendar_spread/long_calendar-story.png
" mdxType="ImageCard" />
    <br />
    <br />
    <br />
    <br />
    <br />
    {
      /* jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/calendar-spread_put_2022.json" */
    }

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;