
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import Link from '@mui/material/Link';
import StraddleShort from "../../components/Pdf/StraddleShort"
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_straddle/shortstraddle_videos0.mp4' mdxType="ReactPlayer" />
  </div>;
export const Videos10 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_straddle/shortstraddle_videos10.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'How to setup Long Straddle?',
  subtitle: 'The straddle purchase consists of buying both a put and a call based on the same terms - at-the-money strikes, the same underlying stock, the same strike price, and the same expiration date.'
}, {
  title: 'How much does it cost to setup?',
  subtitle: 'In a long straddle, we are buying options, so initial cash flow is negative - a debit option strategy.'
}, {
  title: 'How do you calculate Max Profit?',
  subtitle: 'The maximum profit from a long straddle position is unlimited if there is no upside limit to where the underlying price can go.'
}, {
  title: 'How do you calculate Max Loss?',
  subtitle: 'A long straddle has only long options, not short ones, so at expiration there is no assignment risk. Losses are limited to the premium paid.'
}, {
  title: 'How do stock price changes affect profitability?',
  subtitle: 'A long straddle has only long options, not short ones, so at expiration there is no assignment risk. Losses are limited to the premium paid.'
}, {
  title: ' What is the impact of time on profitability?',
  subtitle: 'Over time, the position loses value. Due to negative theta. See the interactive below for more information'
}, {
  title: ' What is the impact of volaitlity on profitability?',
  subtitle: 'Long option positions gain value as volatility increases. As a straddle is composed of two long positions, its value increases as volatility rises'
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const UnderLine = makeShortcode("UnderLine");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
Videos10,
DefaultBox,
Text4,
componetArray,
indexArray,
items1
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">












    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/long_iron_condor_spell.png" mdxType="ImageCard" />
      <br />
      <br />
      <p>{`Long Condor is used to profit from  `}<UnderLine className="thick" mdxType="UnderLine">{`neutral price movements`}</UnderLine>{`, and volatility drop. `}</p>
    </Container>

    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Basics"}/> */
      }
    </Container>
    <h1>{`Long Condor`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/long_condor_scripted_2.json" ggbParameters={ggbParameters} appId="id1_long_condor" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "videos0"
    }, {
      component: <Videos10 mdxType="Videos10" />,
      button: "videos10"
    }]} mdxType="GeogebraDraw" />
    <h1>{`Short Condor`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/short_condor_scripted_2.json" ggbParameters={ggbParameters} appId="id2_short_condor" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "videos0"
    }, {
      component: <Videos10 mdxType="Videos10" />,
      button: "videos10"
    }]} mdxType="GeogebraDraw" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;