import React from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

const FlipCard = (props) => {
    return (
       
  <Grid container justifyContent="center" alignItems="center" >
  <Flippy
    flipOnHover={false} // default false
    flipOnClick={true} // default false
    flipDirection="horizontal" // horizontal or vertical
    // if you pass isFlipped prop component will be controlled component.
    // and other props, which will go to div
    style={{border: 'none', borderWidth : 0, border : 0, padding: 0}}
  >
    <FrontSide
        style={{border: 'none', borderWidth : 0, border : 0, padding: 0, height: '100%', backgroundColor: "#1b1a1a"}}

    >
       <Box
        component="img"
        sx={{
          height: '100%',
          width: '100%',
          maxHeight: {md: 500}
        }}
        alt="A figure."
        src={props.frontimg}
      />
    </FrontSide>
    <BackSide
         style={{border: 'none', borderWidth : 0, border : 0, padding: 0, backgroundColor: "#1b1a1a"}}
        >
          <Box
        component="img"
        sx={{
          height: '100%',
          width: '100%',
          maxHeight: {md: 500}
        }}
        alt="A figure."
        src={props.backimg}
      />
    </BackSide>
  </Flippy>


</Grid>
    
    );
};

export default FlipCard;