
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import Demo from '../../components/SimpleVideoCard';
import ContentMedia from '../../components/ContentMedia';
import Container from "../../components/Layouts/Container";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};

const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <h1>{`Puts Buyer and Seller incentives`}</h1>
 <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/puts_buyer_seller.json" ggbParameters={ggbParameters} appId="DefinfitionVector2" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box1 mdxType="Box1" />,
      button: "textn1"
    }, {
      component: <Box2 mdxType="Box2" />,
      button: "textn2"
    }]} mdxType="GeogebraDraw" />
  
    <container>
      <p>{`Possible reasons for early exercise:`}</p>
      <p>{`A deep ITM option has no time premium remaining. If the bid is less than the intrinsic value and someone sells at that price, the buyer can do a Discount Arbitrage to capture the difference.`}</p>
      <h2>{`Header`}</h2>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`An ITM puts' time premium is less than a pending dividend, so a dividend arbitrage is possible.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Dumb money that doesn't realize that exercising an ITM option with remaining time premium throws that time premium away`}</p>
        </li>
      </ul>
    </container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;