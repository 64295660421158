import React, { useEffect, useState, useReducer } from "react";
import "../markdown-styles/styles.css"
import Typography from '@mui/material/Typography';
import Draggable from 'react-draggable';





export default function ToolTipComponent(props) {
    return (
    <Draggable defaultPosition={{x: 0, y: 0}}>
    <div  style={{ 
        margin: "auto",
        width: "200px",
        textAlign: "center",
        color: "lightsteelblue",
        // marginTop: "10px",
        // minHeight: "70px",
        backgroundColor: "#1e1e1e",
        position: "absolute",
        // left: `${props.mouseOverIndex.x }px`,
        // top: `${props.mouseOverIndex.y }px`,
        right: '60px',
        top: '50px',
        zIndex: 9999999999999	

        }}>
       {props!=null && <div>
         <div >

         <Typography variant="h5" style={{color:"#ce93d8"}} display="inline" >
          {props.mouseOverIndex.id}
         </Typography>
         <Typography variant="caption"  display="block" >
          <span> {props.mouseOverIndex.company}</span>
          <Typography variant="caption" style={{color:"#ffb74d"}} display="block" gutterBottom>
          {props.mouseOverIndex.industry}
         </Typography>
         <Typography variant="caption" style={{ marginRight: "10px"}} display="inline" gutterBottom>
             PerfWeek: {props.mouseOverIndex.PerfWeek}%
         </Typography>
          <span className="spacer">PerfMonth: {props.mouseOverIndex.PerfMonth}%</span>
          <span className="spacer">PerfYear: {props.mouseOverIndex.PerfYear}%</span>
          <br/>
          <span className="spacer">Click to Drag</span>
          </Typography>
          </div>    
        </div>
       }
       </div>
       </Draggable>
      );


}
