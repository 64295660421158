import React, { useState } from 'react';
import MyDraggableComponent from './CopyDraggableComponent';
import { FaVideo, FaMusic, FaLink } from 'react-icons/fa';



const Marker = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMarkerClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
    <div className="marker"   style={{
      position: 'absolute',
      left: props.left,
      top: props.top,
      backgroundColor: 'red',
      borderRadius: '50%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '60px',
      height: '60px'
    }} onClick={handleMarkerClick}>
      <span style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>{props.text}</span>
      {isModalOpen && (
        <MyDraggableComponent setIsModalOpen={setIsModalOpen}>
          {props.children}
        </MyDraggableComponent>
      )}
      <br/>
      {props.type === 'video' && <FaVideo />}
      {props.type === 'audio' && <FaMusic />}
      {props.type === 'link' && <FaLink />}
    </div>
    </>

  );
};

export default Marker;