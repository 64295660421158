import { style } from '@mui/system'
import React, { useRef } from 'react'
import ReactPlayer from 'react-player'
import './style.css'
const VideoPlayer = ({ buttons, width, height, url }) => {
  const ref = useRef(null)


  function str_pad_left(string,pad,length) {
    return (new Array(length+1).join(pad)+string).slice(-length);
}


  function minutesToSeconds(n) {
    var minutes = Math.floor(n);
    
    var seconds = (n % 1)*100

    return (Math.round(seconds) + minutes*60)
}


  return (
    <div >
        <div className="video-player" style={{padding: "10px"}} >
      <ReactPlayer
        ref={ref}
        url={url}
        playing
        controls
        config={{ file: { attributes: { id: 'audio-element' }},  playerVars: {
        // start: 13, end: 18
      } }}
        width={width}
        height={height}
      />
      </div>
      <span style={{color: 'gray'}}>Navigate</span>
      <div className="buttons-container" style={{overflowY: 'scroll', maxHeight: '45vh', width: {width}, padding: '10px'}}>
      {buttons.map((button, index) => (
        <button key={index} onClick={() => ref.current.seekTo(minutesToSeconds(button.time))}>{button.label} {button.time}  </button>
      ))}
      </div>
    </div>
  )
}

export default VideoPlayer