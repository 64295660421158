
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
export const items1 = [{
  title: 'What is Time spread? ',
  subtitle: "Time spreads involve options of same underlying stock, the same series (either both calls or both puts), but different expiration months"
}, {
  title: 'What is a Calendar spread?',
  subtitle: 'A Calendar spread uses options of the same underlying stock (all calls or all puts), same strike, but different expiration months. For example:  Long calendar = Buy 1 June $100 call and sell 1 March $100 call. Short calendar = Buy 1 March $100 call and sell 1 June $100 call '
}, {
  title: 'What is a Diagonal spread?',
  subtitle: 'Diagonal spread is a Calendar spread involving different strike prices. The risk profile is not linear. Long Diagonal = Buy 1 June $120 put and sell 1 March $100 put. Short calendar = Buy 1 March $100 put and sell 1 June $120 put '
}, {
  title: 'Non linear Risk profile',
  subtitle: 'Since options with different expirations are used, risk profile/payoff is not linear'
}];
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const indexArray = {
  button1: 1,
  text14: 1
};
export const references = [{
  title: 'Short Diagonal spread with puts ',
  href: "https://www.fidelity.com/learning-center/investment-products/options/options-strategy-guide/short-diagonal-spread-puts"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const References = makeShortcode("References");
const layoutProps = {
  items1,
meta,
ggbParameters,
opts,
Box1,
Intro,
Text4,
indexArray,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BEARISH Diagonal with Puts`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <br />
      <br />
    </Container>

    <Container mdxType="Container">
  <Content items={items1} title={"Time spreads introduction"} mdxType="Content" />
    </Container>







  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/diagonal_puts_bearish/bearish_long_diagonal_with_puts_2.json" ggbParameters={ggbParameters} appId="id_calendar_short" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    {
      /* jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/short_calendar_spread_put_trade.json" 
      https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/calendar_spread_puts_credit-diagonal.json*/
    }

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;