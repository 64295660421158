import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { FaLink } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#9CC5F0',
    color: 'black',
    padding: theme.spacing(1),
    textDecoration: 'none',
    position: 'absolute',
    top: props => props.y + '%',
    left: props => props.x + '%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    wordWrap: 'break-word',
    maxWidth: '100px',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  heading: {
    fontSize: '1.2em',
    marginBottom: theme.spacing(1),
  },
  caption: {
    fontSize: '0.8em',
  },
}));

function BlueLink(props) {
  const classes = useStyles(props);

  return (
    <Link href={props.url} className={classes.root} target="_blank">
      <div className={classes.linkContainer}>
        <FaLink />
        <div>{props.heading}</div>
      </div>
      <div className={classes.caption}>{props.caption}</div>
    </Link>
  );
}

export default BlueLink;
