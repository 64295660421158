import React from "react";

const RangeIndicator = ({ min, max, value, width, height, lineColor }) => {
  const position = ((value - min) / (max - min)) * 100;
  return (
    <div className="range-indicator" style={{ width, height }}>
      <span className="min" style={{ fontSize: "12px" }}>
        {min}
      </span>
      <div
        className="line"
        style={{
          marginLeft: "5%",
          marginRight: "5%",
          backgroundColor: lineColor
        }}
      >
        <span
          className="current"
          style={{
            left: `${position}%`
          }}
        ></span>
        <span className="currentText"
         style={{
          marginTop: "10px",
          fontSize: "10px",
          position: "absolute",
          color: "black",
          top: "100%",
          left: `${position}%`,
        }}
        >
          {value}
        </span>
      </div>
      <span className="max" style={{ fontSize: "10px" }}>
        {max}
      </span>
    </div>
  );
};

export default RangeIndicator;
