
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import Link from '@mui/material/Link';
export const items1 = [{
  title: 'What is risk of selling Put options?',
  subtitle: "There is unlmited risk if the stock drops above the sold Put strike. Seller is obliged to buy at higher strike. If the Put seller does not hold cash to cover, he has to raise funds to buy the asset. "
}, {
  title: 'What is Initial Margin requirement?',
  subtitle: 'An Initial Margin Requirement refers to the percentage of equity required when an investor opens a position. 50% of total Asset value can be margined'
}, {
  title: 'What is Maintenance Margin?',
  subtitle: "The minimum equity that an investor must maintain in a margin account after a purchase has been made is maintenance margin. Brokers typically set maintenance margin at 25 percent. 30% is standard.  In the event that equity falls below this treshold, the Broker will ask you to add more funds to raise equity above the maintenance requirement"
}, {
  title: 'What is Margin call? ',
  subtitle: "This is a demand from broker to add money to increase equity above maintenace requirement or close out your position"
}, {
  title: 'What are advantages of Margin Trading?',
  subtitle: 'A trader can leverage the value of securities she already owns to increase the size of her investment through margin.'
}];
export const items2 = [{
  title: 'When selling naked options, margin requirements will change with stock movement',
  subtitle: "ITM posiiton will require have a higher margin requirment. Likewise, more volatile stocks have larger maintenance margin. Try changing the variables in the left panel of the app above"
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const FlipCard = makeShortcode("FlipCard");
const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    {
      /* margin_Put_seller */
    }
    <h1>{`Margin requirements for selling uncovered Put`}</h1>
    <br />
    <br />
    <h1>{`Example`}</h1>
    <br />
    <br />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/margin_Put_seller/short_put_margin_fixed.png
" mdxType="ImageCard" />
    <br />
    <br />

    <Container mdxType="Container">
  <Content items={items1} title={"Margin for Sold Put"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/MARGINS/margin_naked_put_4.json" ggbParameters={ggbParameters} appId="appId1margincall" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    <h1>{`QUESTION 1`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/margin_call/putq1_f.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/margin_call/putq1_b.png" mdxType="FlipCard" />
    <br />
    <br />

    <Container mdxType="Container">
  <Content items={items2} title={"Margin requirement for sold puts"} mdxType="Content" />
    </Container>

  <Container mdxType="Container">
   <Typography variant="subtitle1" gutterBottom component="div" color="gray" mdxType="Typography">
        References
      </Typography>
    <Typography variant="caption" display="block" gutterBottom color="gray" mdxType="Typography">
        Keith Cuthbertson, Dick Nitzsche,Niall Sillivan - Derivatives theory and Practice
        <br />
        <br />
    <Link href="https://cdn.cboe.com/resources/options/margin_manual_april2000.pdf" mdxType="Link">
     {'CBOE Options manual'}
    </Link>
      <br />
    <Link href="   https://www.tradestation.com/pricing/options-margin-requirements/" mdxType="Link">
     {'Trade station margin requirements'}
    </Link>
    <br />
      </Typography>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;