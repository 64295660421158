import React from 'react';
import RangeIndicator from './RangeIndicator';
import "./styles.css";

const RangeIndicatorList = ({ data }) => {
  return (
    <table>
      <tbody>
        <tr>
          {data.map((item, index) => (
            <td key={index}>
              <RangeIndicator min={item.min} max={item.max} value={item.value} width={item.width} height={item.height} />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default RangeIndicatorList;