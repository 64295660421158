
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import SimpleAccordion from "../../components/SimpleAccordian";
import IfPaidUser from "../../components/IfPaidUser";
import useFetchData from "../../components/UseFetchData";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ReactPlayerComp from "../../components/ReactPlayerComp";
import SimpleDialog from "../../components/SimpleDialog";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import BlueLink from "../../components/Layouts/BlueLink";
import "../../components/style.css";
import LandmarkButton from '../../components/LandmarkButton';
import MyAudioPlayer from '../../components/Layouts/AudioPlayer';
import MyDraggableComponent from "../../components/DraggableComponent";
import GRCard from "../../components/GRCard";
import { Carousel } from 'react-responsive-carousel';
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "What is the capital of France?",
  // image: "https://example.com/france.jpg",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question2 = {
  text: "What is the capital of France?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question3 = {
  text: "What is the capital of France?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const topics = [{
  title: 'Topic Australia',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
        <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
          position: 'relative',
          width: '100%',
          height: '50%'
        }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/QUESTION_PHILANTROPHY/cra_quiz.png" style={{
            width: '100%',
            height: 'auto'
          }} />
              <LandmarkButton id={1} x={4} y={25} text="r s_t dt represents drift of the asset price, which reflects the overall direction of the price movement." mdxType="LandmarkButton" />
              <LandmarkButton id={2} x={35} y={25} text="product of sigma S_t and small change in brownian process dB_t, represents the random diffusion of the asset price, which reflects the random fluctuations in the price due to various factors such as market demand, supply, and investor sentiment" mdxType="LandmarkButton" />
            </div>
          </>
        </ImageAnnotation>
      </div>
}, {
  title: 'Topic India. what is this is a long button. how does it look',
  component: () => <div style={{
    backgroundImage: "url('https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/QUESTION_PHILANTROPHY/table_image.png')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
      <Marker left="25%" top="55%" mleft="50%" mtop="25%" text="1" mdxType="Marker">
                  <Box1 mdxType="Box1" /> 
      </Marker>
      <BlinkingButton buttonPosition={{
      left: "76%",
      top: "64%"
    }} text={"3"} url={"https://options.21ifm.com"} mdxType="BlinkingButton" />
      <BlueLink url="https://options.21ifm.com/sofr" text="sofr" mdxType="BlueLink" />
      <div style={{
      left: "50%",
      top: "60%",
      position: 'absolute',
      display: 'flex'
    }}>
      <SimpleDialog buttonPosition={{
        left: "0%",
        top: "0%"
      }} title={'Options Greeks in detail in 2D and 3D. Options Greeks in detail in 2D and 3D'} text={"1.1"} mdxType="SimpleDialog">
            <Container mdxType="Container">
              <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/landscape/strategy_diag_8.json" ggbParameters={ggbParameters} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[{
            component: <Box2 mdxType="Box2" />,
            button: "longcall"
          }]} mdxType="GeogebraDraw" /> 
            </Container>
      </SimpleDialog>
      <ButtonMailto label="Email this" mailto="mailto:office@21ifm.com?subject=Note on SOFR chapter" top={"91%"} left={"88%"} mdxType="ButtonMailto" />
      </div>
      <div style={{
      left: "79%",
      top: "40%",
      position: 'absolute',
      display: 'flex'
    }}>
      <SimpleDialog buttonPosition={{
        left: "0%",
        top: "0%"
      }} title={'Options Greeks in detail in 2D and 3D. Options Greeks in detail in 2D and 3D'} text={"2"} mdxType="SimpleDialog">
            <Container mdxType="Container">
              <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/landscape/strategy_diag_8.json" ggbParameters={ggbParameters} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[{
            component: <Box2 mdxType="Box2" />,
            button: "longcall"
          }]} mdxType="GeogebraDraw" /> 
            </Container>
      </SimpleDialog>
      </div>
  </div>
}, {
  title: 'Topic India. what is this is a long button. how does it look',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
        <Box1 mdxType="Box1" /> 
  </div>
}, {
  title: 'Text',
  component: () => <div style={{
    backgroundImage: "url('https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/QUESTION_PHILANTROPHY/table_image.png')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'Video',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question3} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'Image',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
} // Add more topics here
];
export const items1 = [{
  title: 'Which distribution should be used to model asset prices?',
  subtitle: 'Log Normal distribution. Since the lognormal distribution is bound by zero on the lower side, it is perfect for modeling asset prices that cannot take negative values.'
}, {
  title: 'How is uncertainity in future prices modelled?',
  subtitle: 'Uncertainty is modelled by a probability density function.Its mean is assumed to approximate expected future price.'
}, {
  title: 'What is the required rate of return?',
  subtitle: "The required rate of return (RRR) is the minimum return an investor will accept for owning a company's stock, as compensation for a given level of risk associated with holding the stock."
}, {
  title: 'How do you calculate present value?',
  subtitle: 'Present value (PV) is the current value of a future sum of money or stream of cash flows given a specified rate of return. Future cash flows are discounted at the discount rate, and the higher the discount rate, the lower the present value of the future cash flows'
}];
export const items2 = [{
  title: 'Pricing financial assets',
  subtitle: 'Investors use present value to determine whether the price they pay for an investment is reasonable'
}, {
  title: 'Discount rates can be confusing.',
  subtitle: ' Most people immediately grasp the concept of compound growth. Discounting is merely the inverse of growing.'
}];
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MyCarousel = makeShortcode("MyCarousel");
const Marker = makeShortcode("Marker");
const ImageAnnotation = makeShortcode("ImageAnnotation");
const BlinkingButton = makeShortcode("BlinkingButton");
const Box2 = makeShortcode("Box2");
const ButtonMailto = makeShortcode("ButtonMailto");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics,
items1,
items2,
ggbParameters,
ggbParameters2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">











    <h1>{`SOFR`}</h1>




    <Container mdxType="Container">
  <div>
   <MyCarousel topics={topics} mdxType="MyCarousel" />
  </div>
    </Container>
 <div style={{
      position: 'absolute',
      left: "25%",
      top: '1%'
    }}>
   <Marker left="25%" top="5%" mleft="50%" mtop="25%" text="1" mdxType="Marker">
          <Box1 mdxType="Box1" /> 
        </Marker>
  </div>
    <Container mdxType="Container">
      <MyAudioPlayer backgroundImageUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/sofr_1.png" buttonPositions={[{
        top: 50,
        left: 50,
        audioUrl: 'https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/audio2.mp3'
      }, {
        top: 35,
        left: 75,
        audioUrl: 'https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/audio1.mp3'
      }]} mdxType="MyAudioPlayer" />
    </Container>
    <h1>{`BASIC MATHEMATICS TO UNDERSTANG BLACK SCHOLES OPTIONS PRICING`}</h1>
    <Container mdxType="Container">
      <ImageAnnotation mdxType="ImageAnnotation">
  <>
     <div style={{
            position: 'relative',
            width: '100%'
          }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/const_fluc2.png" style={{
              width: '100%',
              height: 'auto'
            }} />
        
        {
              /* <Marker left="25%" top="5%"  mleft="50%" mtop="25%"   text="1" >
               <p>Modal content goes here...</p>
               <Player/> 
              </Marker>
              <Marker left="5%" top="25%"  mleft="50%" mtop="25%"  text="2" >
               <p>Modal content goes here...</p>
              </Marker>
              <Marker left="75%" top="55%" mleft="20%" mtop="20%"  text="3" >
               <p>Modal content goes here...</p>
              </Marker> */
            }
        <LandmarkButton id={1} x={20} y={60} text=" This equation  describes the evolution of a financial asset over time, where S_t represents the value of the asset at time t, S_0 represents the initial value of the asset at time 0, and r represents the growth rate of the asset. This equation can be used to model the expected evolution of an asset over time, assuming that it grows at a constant rate without any random fluctuations" mdxType="LandmarkButton" />
        <LandmarkButton id={2} x={65} y={60} text="This equation describes the evolution of a financial asset over time, but it includes an additional term sigma and B_t, 
, which represents the random fluctuations in the value of the asset due to a standard Brownian motion process. This equation can be used to model the evolution of an asset over time, taking into account the inherent randomness and uncertainty of the asset's value." mdxType="LandmarkButton" />
      </div>
      </>
      </ImageAnnotation>
    </Container>
    <Container mdxType="Container">
      <ImageAnnotation mdxType="ImageAnnotation">
  <>
     <div style={{
            position: 'relative',
            width: '100%'
          }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/brownian_geometric_vs.png" style={{
              width: '100%',
              height: 'auto'
            }} />
        
        <Marker left="25%" top="5%" mleft="50%" mtop="25%" text="1" mdxType="Marker">
          <Box1 mdxType="Box1" /> 
        </Marker>
        <Marker left="5%" top="25%" mleft="50%" mtop="25%" text="2" mdxType="Marker">
          <div style={{
                overflowY: 'scroll',
                maxHeight: '75vh'
              }}>
            <MyAudioPlayer backgroundImageUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/sofr_1.png" buttonPositions={[{
                  top: 50,
                  left: 50,
                  audioUrl: 'https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/audio2.mp3'
                }, {
                  top: 35,
                  left: 75,
                  audioUrl: 'https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/audio1.mp3'
                }]} mdxType="MyAudioPlayer" />
        </div>
        </Marker>
        <Marker left="75%" top="55%" mleft="20%" mtop="20%" text="3" mdxType="Marker">
          <p>Modal content goes here...</p>
        </Marker>
        <LandmarkButton id={1} x={20} y={35} text="Mathematically, standard Brownian motion can be described using the concept of a random walk, which is a mathematical model for a sequence of random steps. t represents the length of time that has passed and Z is a random variable with a standard normal distribution (mean of 0 and standard deviation of 1)" mdxType="LandmarkButton" />
        <LandmarkButton id={2} x={85} y={35} text="Geometric brownian motion Is a process that models the evolution of a system over time based on the assumption that the system undergoes exponential growth or decay.This equation describes how the value of the asset changes over time. " mdxType="LandmarkButton" />
      </div>
      </>
      </ImageAnnotation>
    </Container>
    <Container mdxType="Container">
      <ImageAnnotation mdxType="ImageAnnotation">
  <>
     <div style={{
            position: 'relative',
            width: '100%'
          }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/brownian_motion_code_2.png" style={{
              width: '100%',
              height: 'auto'
            }} />
        
        {
              /* <Marker left="25%" top="5%"  mleft="50%" mtop="25%"   text="1" >
               <p>Modal content goes here...</p>
               <Player/> 
              </Marker>
              <Marker left="5%" top="25%"  mleft="50%" mtop="25%"  text="2" >
               <p>Modal content goes here...</p>
              </Marker>
              <Marker left="75%" top="55%" mleft="20%" mtop="20%"  text="3" >
               <p>Modal content goes here...</p>
              </Marker> */
            }
        <LandmarkButton id={1} x={4} y={25} text="r s_t dt represents drift of the asset price, which reflects the overall direction of the price movement." mdxType="LandmarkButton" />
        <LandmarkButton id={2} x={35} y={25} text=" product of sigma S_t and small change in brownian process dB_t, represents the random diffusion of the asset price, which reflects the random fluctuations in the price due to various factors such as market demand, supply, and investor sentiment " mdxType="LandmarkButton" />
      </div>
      </>
      </ImageAnnotation>
    </Container>
    <Carousel width="100%" showThumbs={true} showStatus={true} infiniteLoop showArrows // emulateTouch
    autoPlay interval={7000} dynamicHeight={false} useKeyboardArrows transitionTime={1500} mdxType="Carousel">
          <div> 
            <ImageAnnotation mdxType="ImageAnnotation">
                <>
                  <div style={{
              position: 'relative',
              width: '100%'
            }}>
                      <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/brownian_motion_code_2.png" style={{
                width: '100%',
                height: 'auto'
              }} />
                      <LandmarkButton id={1} x={4} y={25} text="r s_t dt represents drift of the asset price, which reflects the overall direction of the price movement." mdxType="LandmarkButton" />
                      <LandmarkButton id={2} x={35} y={25} text=" product of sigma S_t and small change in brownian process dB_t, represents the random diffusion of the asset price, which reflects the random fluctuations in the price due to various factors such as market demand, supply, and investor sentiment " mdxType="LandmarkButton" />
                    </div>
                    </>
            </ImageAnnotation>
          </div> 
          <div>
            <ImageAnnotation mdxType="ImageAnnotation">
                <>
                  <div style={{
              position: 'relative',
              width: '100%'
            }}>
                      <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/pricing_framework/brownian_motion_code_2.png" style={{
                width: '100%',
                height: 'auto'
              }} />
                      <LandmarkButton id={1} x={4} y={25} text="r s_t dt represents drift of the asset price, which reflects the overall direction of the price movement." mdxType="LandmarkButton" />
                      <LandmarkButton id={2} x={35} y={25} text=" product of sigma S_t and small change in brownian process dB_t, represents the random diffusion of the asset price, which reflects the random fluctuations in the price due to various factors such as market demand, supply, and investor sentiment " mdxType="LandmarkButton" />
                    </div>
                    </>
            </ImageAnnotation>
          </div>
    
  </Carousel>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`Interactive`}</h1>

    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Basics"}/> */
      }
    </Container>
    <br />
    {
      /* sonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/pf2.json" */
    }

    <Container mdxType="Container">
  {
        /* <Content items={items2} title={"Where and how can I use this?"}/> */
      }
    </Container>
    <br />


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;