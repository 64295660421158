import React from 'react';
import "./styles.css";

const Avatar = ({ imageUrl, name, title }) => {
  return (
    <div className="avatar">
      <div className="avatar-image-container">
        {imageUrl ? (
          <img src={imageUrl} alt={name} className="avatar-image" />
        ) : (
          <div className="avatar-placeholder">
            {name.charAt(0)}
          </div>
        )}
      </div>
      <div className="avatar-description">
        <div className="avatar-name">{name}</div>
        <div className="avatar-title">{title}</div>
      </div>
    </div>
  );
};

export default Avatar;
