import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import styles from "./style.css";

const LoginDialog = ({ children }) => {
  const [user, setUser] = useState(null);

  // Configure the Google authentication provider
  const provider = new firebase.auth.GoogleAuthProvider();

  // Log in with Google
  const signInWithGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        setUser(result.user);
        // Add the user to the 'linkedinusers' collection in Firestore
        firebase
          .firestore()
          .collection("linkedinusers")
          .doc(result.user.uid)
          .set({
            uid: result.user.uid,
            displayName: result.user.displayName,
            email: result.user.email,
            photoURL: result.user.photoURL,
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };


useEffect(() => {
    // Check if the user is logged in
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);


  if (user) {
    return <>{children}</>;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#333",
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <h2>Welcome. Please log in to continue to free access</h2>
        <button
          style={{
            backgroundColor: "#4285f4",
            color: "white",
            border: "none",
            cursor: "pointer",
            fontSize: "1rem",
            padding: "10px 20px",
            borderRadius: "4px",
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "10px",
          }}
          onClick={signInWithGoogle}
        >
          Sign in with Google
        </button>
      </div>
    );
  }


};

export default LoginDialog;
