
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import { useState } from 'react'
import Alert from '@mui/material/Alert';
import data from '../../data/bullish_long_call.json';
import butterfly3d from "../../data/long_short_butterfly.json"
import GeogebraComponent from "../../components/GeogebraComponent";
import ImageZoom from 'react-image-zooom';
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const short_position = data.data; // change this back to data.data

export const short_position_params = {
  // material_id: "",
  // width: 1800,
  // height: 900,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  ggbBase64: short_position
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const Highlight = ({
  children,
  color
}) => <span style={{
  backgroundColor: color,
  borderRadius: '2px',
  color: '#fff',
  padding: '0.2rem'
}}>
    {children}
  </span>;
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const Figure = props => {
  return <figure class="chart">
      {
      /* <object data={props.data} type="image/svg+xml"></object> */
    }
      <img src={props.data} alt="yoast seo" height={props.height} width={props.width} />
      <figcaption>{props.caption}</figcaption>
    </figure>;
};
export const ggbParameters2 = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const BoxExample = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  minWidth: 300
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const GetGGBLocal = ({
  short_position_params
}) => {
  const [clickItem, setClickItem] = React.useState('');
  const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);

  function handleClick(d) {
    setClickItem(d); // setIsOpen(!isOpen)
    // alert(d)

    forceUpdate();
  }

  return <GeogebraComponent id={"short_position_id"} newParameters={short_position_params} stateChanger={setClickItem} onClickHandle={d => {
    handleClick(d);
  }} mdxType="GeogebraComponent" />;
};
/* <GetGGBLocal short_position_params={short_position_params} /> */

export const references = [{
  title: 'larry Shover: Trading Options. Master uncertainty',
  href: "https://www.amazon.ca/-/fr/Larry-Shover/dp/1576603601"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Container = makeShortcode("Container");
const ImageCard = makeShortcode("ImageCard");
const Text9 = makeShortcode("Text9");
const FlipCard = makeShortcode("FlipCard");
const References = makeShortcode("References");
const layoutProps = {
  opts,
short_position_params,
meta,
Highlight,
ggbParameters,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
Figure,
ggbParameters2,
BoxExample,
GetGGBLocal,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    <Container mdxType="Container">
  {
        /* <ImageZoom src="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/vs_manual.png" /> */
      }
    </Container>
    <h1>{`BULLISH call spread`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/AUG8/BULL_CALL_SPREAD/2.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bullish_call_spread/vertical_bull_spread_calls.png
" mdxType="ImageCard" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`Interactive`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bullish_call_spread/spreads_template_bullish_call_spread_2.json" ggbParameters={ggbParameters2} appId="appId2Template" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 
    <h1>{`Strike selection`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/AUG8/BULL_CALL_SPREAD/7.png
" mdxType="ImageCard" />
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/AUG8/BULL_CALL_SPREAD/8.png
" mdxType="ImageCard" />
      <br />
      <br />
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/AUG8/BULL_CALL_SPREAD/9.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>



    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`QUESTION 1`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/AUG8/BULL_CALL_SPREAD/3.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/AUG8/BULL_CALL_SPREAD/4.png" mdxType="FlipCard" />
    <br />
    <br />
    <br />
    <br />
    <h1>{`QUESTION 2`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/AUG8/BULL_CALL_SPREAD/5.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/AUG8/BULL_CALL_SPREAD/6.png" mdxType="FlipCard" />
    <br />
    <br />
    <h1>{`comparison with Bull put spread`}</h1>
    <Container mdxType="Container"> 
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/AUG8/BULL_CALL_SPREAD/10.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;