import firebase from "../firebase/firebaseIndex";

export default function isPaidUserTest() {
    let user = firebase.auth().currentUser;
    const db = firebase.firestore();
    if (user) {
        let userUID = user.uid
        let docRef = db.collection("users").doc(userUID).collection("payments");

        return docRef.limit(1).get().then((qs) => {  //Note the return here
            if (qs.empty== false) {
                  return true;  //Note the return here
                } else {
                    console.log("No such document!");
                    //Handle this situation the way you want! E.g. return false or throw an error
                    return false;
                }
            }).catch(error => {
                console.log("Error getting document:", error);
                return false
            });
    } else {
       return false
    }

}
