
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import firebase from 'firebase';
import { firebaseAuth } from '../../provider/AuthProvider';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  signInButton: {
    marginTop: theme.spacing(2)
  },
  successMessage: {
    marginTop: theme.spacing(2),
    color: 'green'
  }
}));

const layoutProps = {
  useStyles
};
const MDXLayout = function SignIn() {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
//   const { currentUser } = useContext(firebaseAuth);
  const {
    token,
    handleSignout,
    inputs,
    currentUser,
    setCurrentUser,
    setPaymentFlag,
    paymentFlag,
  } = useContext(firebaseAuth);
 const handleSignIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await firebase.auth().signInWithPopup(provider);
      setSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };
return (
    <div className={classes.root}>
      {currentUser ? (
        <p>You are signed in as {currentUser.email}</p>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.signInButton}
          onClick={handleSignIn}
        >
          Sign in with Google
        </Button>
      )}
      {currentUser && success && (
        <p className={classes.successMessage}>You have successfully signed in! {currentUser.email}</p>
      )}
    </div>
  );
}

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;