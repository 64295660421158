
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const items1 = [{
  title: 'How to setup Long Straddle?',
  subtitle: 'This strategy consists of buying two lower strike puts and selling one higher strike put'
}, {
  title: 'How much does it cost to setup?',
  subtitle: 'We are buying two put options and selling one put option, so initial cash flow is negative - a debit option strategy. But, it could be credit depending on the time left to maturity, distance between strikes'
}, {
  title: 'How do you calculate Max Profit?',
  subtitle: 'The maximum profit  is unlimited if there is step drop in price. Other profit scenarios are discussed in the interactive below.'
}, {
  title: 'How do you calculate Max Loss?',
  subtitle: 'Risk is limited and max risk is realized if stock price is at the strike price of the long put at expiration. Other loss scenarios are discussed in the interactive below.'
}, {
  title: 'How do stock price changes affect profitability?',
  subtitle: 'This posiiton has a net postive gamma. Meaning delta and price change have positive relationship. Delta falls as stock price falls, Delta rises as stock price increases '
}, {
  title: ' What is the impact of time on profitability?',
  subtitle: 'This is a net long strategy since we are buying two puts. hence theta is negative. This means that theta and strategy pnl are inversely related. As time approaches maturity, strategy loses value '
}, {
  title: ' What is the impact of volaitlity on profitability?',
  subtitle: 'Net vega is positive since we have two long positions that have positive vega. As volatility increases, strategy pnl gains more value'
}, {
  title: ' Why are longer term options more suitable for this strategy?',
  subtitle: 'Due to time decay, this strategy loses money. There will be less time decay for longer term options and a greater chance due to longer term of the stock moving in the desired direction. '
}];
export const items2 = [{
  title: 'Early Assignment',
  subtitle: 'In case the option is exercised, holders of short stock options have to fulfill their obligations. The risk of short puts is high. If the short puts are exercised, you get 100 shares of stock. If not enough money is in the margin account, an assignment can trigger a margin call'
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const UnderLine = makeShortcode("UnderLine");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`SLIGHTLY BEARISH RATIO SPREAD`}</h1>
    <Container mdxType="Container">
      <p>{`Stratregy benefits from `}<UnderLine className="thick" mdxType="UnderLine">{`slightly bearish stock movement`}</UnderLine>{` towards lower strike.`}</p>
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/neutral_put_ratio.png" mdxType="ImageCard" />
      <br />
      <br />
      <p>{`We sell two puts with a lower strike, and we buy one with a higher strike. Net cost credit/debit depends on volatility and the moneyness of options (see example below) `}</p>
    </Container>

    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Learning Objectives"}/> */
      }
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/put_ratio_spread_neutral_scripted.json" ggbParameters={ggbParameters} appId="appId1OutRatioBS" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  {
        /* <Content items={items2} title={"Risks"}/> */
      }
    </Container>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;