import { useState, useEffect } from "react";
import firebase from "../firebase/firebaseIndex";
import isPaidUserTest from "./isPaidUserTest";

export default function usePremiumStatus(user: firebase.User) {
  const [premiumStatus, setPremiumStatus] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      const checkPremiumStatus = async function () {
        setPremiumStatus(await isPaidUserTest());
      };
      checkPremiumStatus();
    }
  }, [user]);

  return premiumStatus;
}
