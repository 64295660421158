
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Marker from '../../components/ImageMarkers.js';
import ImageAnnotation from '../../components/ImageAnnotation.js';
import ReactPlayer from 'react-player'
import  RangeIndicatorList from "../../components/Layouts/RangeIndicatorList";
import MaskedWord from "../../components/MaskedWords";
import  Avatar from "../../components/Layouts/Avatar";
import MultipleGeogebra from "../../components/MultipleGeogebra";
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url='https://www.youtube.com/watch?v=0_8YEglCFdU' playing controls config={{
      file: {
        attributes: {
          id: 'audio-element'
        }
      }
    }} width='320px' height='180px' mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>Seek to 00:10</button>
    </div>;
};
export const MaskedWordsComp1 = () => {
  const sentences = ['Implied volatility is a measure of the expected volatility of the underlying stock or index over the life of the option. It is important in options because it helps to determine the value of the option.', 'The value of an option is influenced by several factors, including the underlying stock price, the strike price of the option, the time until expiration, the risk-free interest rate, and the expected volatility of the underlying stock'];
  const words = ['value', 'expected'];
  return <MaskedWord sentences={sentences} words={words} mdxType="MaskedWord" />;
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Container = makeShortcode("Container");
const layoutProps = {
  Player,
MaskedWordsComp1
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Avatar imageUrl="https://i.pravatar.cc/301" name="John Smith" title="Software Engineer" mdxType="Avatar" />
    <Container mdxType="Container">
      <MultipleGeogebra mdxType="MultipleGeogebra" />
    </Container>
    <Container mdxType="Container">
      <MaskedWordsComp1 mdxType="MaskedWordsComp1" />
    </Container>
    <Avatar imageUrl="https://i.pravatar.cc/300" name="John Smith" title="Software Engineer" mdxType="Avatar" />
    <ImageAnnotation mdxType="ImageAnnotation">
  <>
     <div style={{
          position: 'relative',
          width: '100%'
        }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/VOLATILITY/images_vol/four.png" style={{
            width: '100%',
            height: 'auto'
          }} />
        
        <Marker left="25%" top="5%" mleft="50%" mtop="25%" text="1" mdxType="Marker">
   
          <Player mdxType="Player" /> 
        </Marker>
        <Marker left="5%" top="25%" mleft="50%" mtop="25%" text="2" mdxType="Marker">
            <div style={{
              textAlign: 'center'
            }}>
                 <span>Incorrect answer. 
                 Try other options.</span> 
            </div>
  
        </Marker>
        <Marker left="75%" top="55%" mleft="20%" mtop="20%" text="3" mdxType="Marker">
           <div style={{
              textAlign: 'center'
            }}>
                <span>Incorrect answer. 
                 Try other options.</span> 
            </div>
        </Marker>
      </div>
      </>
  </ImageAnnotation>
  <Avatar imageUrl="https://i.pravatar.cc/303" name="John Smith" title="Software Engineer" mdxType="Avatar" />
    <Container mdxType="Container">
      <RangeIndicatorList data={[{
        min: 0,
        max: 100,
        value: 50,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 15,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 25,
        width: 400,
        height: 50
      }]} mdxType="RangeIndicatorList" />
      <RangeIndicatorList data={[{
        min: 0,
        max: 100,
        value: 50,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 15,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 25,
        width: 400,
        height: 50
      }]} mdxType="RangeIndicatorList" />
      <RangeIndicatorList data={[{
        min: 0,
        max: 100,
        value: 50,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 15,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 25,
        width: 400,
        height: 50
      }]} mdxType="RangeIndicatorList" />
      <RangeIndicatorList data={[{
        min: 0,
        max: 100,
        value: 50,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 15,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 25,
        width: 400,
        height: 50
      }]} mdxType="RangeIndicatorList" />
      <RangeIndicatorList data={[{
        min: 0,
        max: 100,
        value: 50,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 15,
        width: 400,
        height: 50
      }, {
        min: 0,
        max: 100,
        value: 25,
        width: 400,
        height: 50
      }]} mdxType="RangeIndicatorList" />
    </Container>

    <Container mdxType="Container">
      <MaskedWordsComp1 mdxType="MaskedWordsComp1" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;