import firebase from "../firebase/firebaseIndex";

import getStripe from "./initializeStripe";

export async function createCheckoutSession(uid: string) {
  const db = firebase.firestore();
  // Create a new checkout session in the subollection inside this users document
  const checkoutSessionRef = await db
    .collection("users")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      // replace the price_XXX value with the correct value from your product in stripe.
      mode: "payment",
      price: "price_1LgzEBG0QNwLjqA664myO7eM",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

  // Wait for the CheckoutSession to get attached by the extension
  checkoutSessionRef.onSnapshot(async (snap) => {
    // @ts-ignore
    const { sessionId } = snap.data();
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await getStripe();
      // @ts-ignore
      stripe.redirectToCheckout({ sessionId });
    }
  });
}
