
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import ButtonMailto from "../../components/ButtonMailto.js";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
import HighlightSpeak from "../../components/HighlightSpeak";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const indexArray = {
  button1: 1,
  text14: 1
};
export const references = [{
  title: 'What is Time spread? ',
  href: "https://www.fidelity.com/learning-center/investment-products/options/options-strategy-guide/short-calendar-spread-puts"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const ContentMedia = makeShortcode("ContentMedia");
const LoadPdf = makeShortcode("LoadPdf");
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
Text4,
indexArray,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <h1>{`Neutral Calendar`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/neutral_calendar_spread/long_calendar_intro.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/neutral_calendar_spread/long_calendar-story.png
" mdxType="ImageCard" />
    <br />
    <h1>{`Interactive 1`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/neutral_calendar_spread/calendar_template_neutral_puts_4.json" ggbParameters={ggbParameters} appId="id3" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    {
      /* jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/calendar-spread_put_2022.json" */
    }

    <Container mdxType="Container">
  <ContentMedia title="User Manual" mdxType="ContentMedia">
  <LoadPdf file="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/debit_calendar_spread_fixed.pdf" mdxType="LoadPdf" />
  </ContentMedia>
    </Container>
    <br />
    {
      /* # Interactive 2
        <GeogebraDraw 
         jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/spreads_template_calendar_neutral_calls.json"
         ggbParameters={ggbParameters}
         appId="id_calls"
         email="pavanmirla@gmail.com"
         data= {[
           { component: <Intro/>, button: "text11" },
           {component: <Text4/>, button:  "text3"}
         ]}
       /> */
    }
    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;