import React, { useRef, useState,useEffect } from 'react';



const DrawingCanvas = ({  onSave }) => {
  const canvasRef = useRef(null);
  const [label, setLabel] = useState('');

  const save = () => {
    const canvas = canvasRef.current;
    const imageData = canvas.toDataURL();
    const trainingData = {
      label: label,
      imageData: imageData
    };

    onSave(trainingData);
  };


  const clear = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    let isDrawing = false;
    let lastX = 0;
    let lastY = 0;

    const handleMouseDown = (event) => {
      isDrawing = true;
      lastX = event.offsetX;
      lastY = event.offsetY;
    };




    const handleMouseMove = (event) => {
      if (!isDrawing) return;
    
      const currentX = event.offsetX;
      const currentY = event.offsetY;
    
      context.beginPath();
      context.moveTo(lastX, lastY);
      context.lineTo(currentX, currentY);
      context.strokeStyle = 'black';
      context.lineWidth = 3;
      context.stroke();
    
      lastX = currentX;
      lastY = currentY;
    };

    const handleMouseUp = (event) => {
      isDrawing = false;
      lastX = event.offsetX;
      lastY = event.offsetY;
    };
    

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  

  return (
    <div>
      <canvas
        ref={canvasRef}
        width={500}
        height={500}
        style={{ border: '1px solid black' }}
      />
      <br />
      <label>
        Label:
        <input type="text" value={label} onChange={(event) => setLabel(event.target.value)} />
      </label>
      <button onClick={save}>Save</button>
      <button onClick={clear}>Clear</button>
    </div>
  );
};

export default DrawingCanvas;