// add useContext
import React, { useContext, useState, useEffect } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import firebase from "firebase";
import { Signup } from "./SignUp";
import {
  useHistory
} from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SimpleAccordion from "./SimpleAccordian";
// import { createCheckoutSession } from "../stripe/createCheckoutSession";


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/logo_ifm.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Signin = () => {
  const classes = useStyles();
  const history = useHistory()
  const {
    handleSignin,
    handleSigninEmailLink,
    inputs,
    setInputs,
    errors,
    token,
    currentUser,
    handlesigninGoogle,
    handleSigninGoogleAfterRedirect
  } = useContext(firebaseAuth);




  useEffect(() => {
    return firebase.auth().onAuthStateChanged((im) => {
      if (im) {
        handleSigninGoogleAfterRedirect(im)

   
        history.push("/")
        // setIsInitialized(true);
        // setUser({
        //   displayName: im.displayName,
        //   email: im.email,
        //   photo: im.photoURL,
        //   uid: im.uid,
        // });
        


      } else {
  
        history.push("/signin")
      }
    });
  }, []);


  const delay = ms => new Promise(res => setTimeout(res, ms));
  
  const handleSubmit =async (e) => {
    e.preventDefault();
    await handleSignin();
    await delay(2000)

    if(window.localStorage.getItem('token') !== null)
     history.push("/")
     else
     history.push("/forgotPassword")

    //  errors.length > 0
    //          ? errors.map((error) => (

    //         ))
    //       :null

  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(inputs);
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitEmailLink = (e) => {
    e.preventDefault();
    console.log("handleSubmitEmailLink");
    handleSigninEmailLink();
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

      <div className={classes.paper}>


      </div>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
             Sign in
          </Typography>
          <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handlesigninGoogle}
            >
              Google Signin
            </Button>
          
        </div>
      </Grid>
    </Grid>
  );
};

export default Signin;