/**
  This CodeSandbox has been automatically generated using
  `codesandboxer`. If you're curious how that happened, you can
  check out our docs here: https://github.com/codesandbox/codesandboxer

  If you experience any struggles with this sandbox, please raise an issue
  on github. :)
*/
import React from "react";
import ReactDOM from "react-dom";
import BlogsApp from "./blogsApp";
import AuthProvider from "./provider/AuthProvider";
import {MDXProvider} from '@mdx-js/react'
import CodeBlock from './markdown-styles/CodeBlock'
import Header1 from './markdown-styles/Header1'
import Header2 from './markdown-styles/Header2'
import Header3 from './markdown-styles/Header3'
import Paragraph from './markdown-styles/Paragraph'
import Link_ from './markdown-styles/Link_'
import UnderLine from './markdown-styles/Underline'
import LoadPdf from './components/Pdf/LoadPdf.jsx'
import ImageCard from './components/ImageCard'
import FlipCard from './components/FlipCard'
import { BlocksProvider } from 'mdx-blocks'
import AppHeader from "./components/AppHeader";
import VideoSection from "./components/VideoSection";
import Content from "./components/Content";
import ContentMedia from "./components/ContentMedia";
import Button from '@mui/material/Button';
import Container from "./components/Layouts/Container";
import GeogebraDraw from "./components/GeogebraDraw";
import MyCarousel from "./components/MyCarousel";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import References from "./components/References";
import ImageAnnotation from "./components/ImageAnnotation";
import Marker from './components/ImageMarkers';
import VideoPlayer from './components/VideoPlayer';
import ReactPlayer from 'react-player'
import MyAudioPlayer from './components/Layouts/AudioPlayer';
import ButtonMailto from "./components/ButtonMailto.js";
import Quiz from "./components/Quiz.js";
import LandmarkButton from './components/LandmarkButton';
import BlinkingButton from './components/BlinkingButton';
import CardsForBlogs from "./CardsForBlogs";
import CardsForBlogsNoNike from "./CardsForBlogsNoNike";

import { Helmet, HelmetProvider } from 'react-helmet-async';

const components = {
    h1:  Header1,
    h2:  Header2,
    h3:  Header3,
    p:   Paragraph,
    a:   Link_,
    pre: CodeBlock,
    CardsForBlogs,
    CardsForBlogsNoNike,
    LoadPdf,
    UnderLine,
    FlipCard,
    ImageCard,
    VideoSection,
    Content,
    Button,
    Container,
    ContentMedia,
    GeogebraDraw,
    Link,
    Grid,
    Box,
    References,
    AppHeader,
    ImageAnnotation,
    Marker,
    ReactPlayer,
    VideoPlayer,
    MyAudioPlayer,
    MyCarousel,
    ButtonMailto,
    Quiz,
    LandmarkButton,
    BlinkingButton,
    wrapper: ({ ...props}) => (
      <div style={{ backgroundColor: '#1E1E1E'}}>
        <main {...props} />
      </div>
    )

  //  code: props => <pre style={{color: 'tomato'}} {...props} />
}

ReactDOM.render(
  [
    <HelmetProvider>
     <Helmet>
       <script type="text/javascript" src="https://cdn.geogebra.org/apps/deployggb.js"
          onLoad={() => {
            window.localStorage.setItem("scriptLoaded", true);
          }}
          ></script>
       </Helmet>
     <MDXProvider components={components}>
      <AuthProvider>
        <BlogsApp />
      </AuthProvider>
    </MDXProvider>


    </HelmetProvider>
  ],
  document.getElementById("root")
);
