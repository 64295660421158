
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
export const Intro_Videos = props => <div style={{
  padding: 0,
  backgroundColor: "#1e1e1e",
  width: "100%",
  marginTop: '50px',
  margin: "auto",
  zIndex: 9999
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} mdxType="ReactPlayer" />
    <br />
  </div>;
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  // material_id: "",
  width: 300,
  height: 450,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "510",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: "green"
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: "yellow"
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const buttons = [{
  label: "Agenda",
  time: 0
}, {
  label: "What is optimization?",
  time: .09
}, {
  label: "cardboard experiment",
  time: .36
}, {
  label: "Volume as a function of height",
  time: 1.14
}, {
  label: "First derivative",
  time: 1.41
}, {
  label: "What is critical point?",
  time: 1.59
}, {
  label: "What is slope of tangent line?",
  time: 2.09
}, {
  label: "Solving for height h to optimize volume",
  time: 2.15
}, {
  label: "Summary",
  time: 2.51
}];
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" playing controls config={{
      file: {
        attributes: {
          id: "audio-element"
        }
      },
      playerVars: {}
    }} width="320px" height="180px" mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>
        What is optimization
      </button>
      <br />
      <button onClick={() => ref.current.seekTo(20)}>
        What is critical point?
      </button>
    </div>;
};
export const ClipVideo = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" startTime={10} endTime={20} playing controls mdxType="ReactPlayer" />
  </div>;
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const Marker = makeShortcode("Marker");
const VideoPlayer = makeShortcode("VideoPlayer");
const AppHeader = makeShortcode("AppHeader");
const ReactPlayer = makeShortcode("ReactPlayer");
const layoutProps = {
  Intro_Videos,
meta,
ggbParameters,
ggbParameters2,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
buttons,
Player,
ClipVideo,
Box11,
Box22
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">












    <h1>{`Optimization basics`}</h1>

    <br />
    <br />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/DEMO/optimization_box.png" mdxType="ImageCard" />
    <Marker left="5%" top="55%" mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
  <VideoPlayer buttons={buttons} width={"80%"} height={"100%"} url={"https://storage.googleapis.com/chapters-ifm/testfolder/DEMO/optimization_v2.mp4"} mdxType="VideoPlayer" />
    </Marker>
    <br />
    <br />
    <AppHeader mdxType="AppHeader" />
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DEMO/optimization_box.json" ggbParameters={ggbParameters} appId="id1" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box2 mdxType="Box2" />,
      button: "text14"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text4"
    }]} mdxType="GeogebraDraw" />




    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;