import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { firebaseAuth } from "../provider/AuthProvider";
import firebase from "firebase";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    display: "flex",
    flexGrow: 1,
  },
  list: {
    display: "flex",
    flexGrow: 1,
  },
  listItem1: {
    marginRight: "0.5em",
    opacity: 0.8,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  listItemText: {
    whiteSpace: "nowrap",
  },
  appbar: {
    backgroundColor: "black",
    color: "lightgray",
    zIndex: theme.zIndex.drawer + 1,
    minHeight: 20,
  },
  ifm: {
    backgroundColor: "#ffcf02",
    color: "black",
    maxWidth: "5em",
    textAlign: "center",
  },
  login: {
    maxWidth: "8em",
    textAlign: "center",
    position: "absolute",
    right: 0,
  },
}));

function AppHeader(props) {
  const classes = useStyles();
  const history = useHistory();
  const { handleSignout, currentUser, paymentFlag } = useContext(firebaseAuth);

  const [isLoggedIn, setIsLoggedIn] = useState(!!currentUser);
  const [hasPaymentFlag, setHasPaymentFlag] = useState(!!paymentFlag);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setHasPaymentFlag(!!paymentFlag);
  }, [paymentFlag]);

  const handleLogin = () => {
    localStorage.setItem("clicked_buy", false);
    history.push("/signin");
  };

  const handleLogout = async () => {
    await handleSignout();
    setIsLoggedIn(false);
    history.push("/signin");
  };

  return (
    <AppBar position="fixed" elevation={1} className={classes.appbar}>
      <Toolbar>
        <List className={classes.list}>
          <ListItem key={"IFM"} className={classes.ifm} component={Link} to={"/"}>
            <ListItemText primary={"IFM"} />
          </ListItem>
          <ListItem
            button
            key={"IFMLONG"}
            className={classes.listItem1}
            component={Link}
            to={"/"}
          >
            <ListItemText primary={"Interactive Mathematics"} />
          </ListItem>

          {isLoggedIn && hasPaymentFlag ? (
            <ListItem
              button
              key={"LOGOUT"}
              className={classes.login}
              onClick={handleLogout}
            >
              <ListItemText primary={"LOGOUT"} />
            </ListItem>
          ) : (
            <ListItem
              button
              key={"LOGIN"}
              className={classes.login}
              onClick={handleLogin}
            >
              <ListItemText primary={"LOGIN"} />
            </ListItem>
          )}
        </List>
      </Toolbar>
      {props.children}
    </AppBar>
  );
}

export default AppHeader;
